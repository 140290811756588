import { LandingProgramNavigationTabProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<LandingProgramNavigationTabProps> = {
	schemaType: "component",
	component: "LandingProgramNavigationTab",
	displayName: "Program Tab",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "title",
					title: "Title",
					mandatory: true,
				},
				{
					type: "ComponentArray",
					title: "Content",
					key: "elements",
					contentType: "components",
					whiteList: [
						"AccordionCollection",
						"BasicContent",
						"BasicText",
						"CardCollection",
						"ChartCollection",
						"CypherCollection",
						"FeaturedAudiovisual",
						"FeaturedBlock",
						"FeaturedContent",
						"FullImageWithText",
						"FullVideo",
						"HighlightQuote",
						"LandingIntro",
						"LogoCollection",
						"NewsHighlightDistributor",
						"NewsSimpleDistributor",
						"OrderedCollection",
						"PeopleDistributor",
						"ProgramsDistributor",
						"ProgramQuickFacts",
						"QuoteTestimonialCollection",
						"QuoteTestimonial",
						"TabsComponent",
						"Wysiwyg",
					],
				},
			],
		},
	],

	default: {
		component: "LandingProgramNavigationTab",
		title: "Lorem ipsum",
		elements: [],
	},

	thumbnails: {
		"1x": "/thumbnails/components/ProgramNavigationTab/thumbnail@1x.png",
		"2x": "/thumbnails/components/ProgramNavigationTab/thumbnail@2x.png",
	},
};

export default schema;
