import { DepartmentsBarProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<DepartmentsBarProps> = {
	schemaType: "component",
	category: "content",
	displayName: "DepartmentsBar",
	component: "DepartmentsBar",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "ReferenceField",
					title: "Departments",
					sources: [{ structuredData: "DEPARTMENTS" }],
					selectionType: ["auto", "manual"],
					key: "data",
				},
			],
		},
	],

	default: {
		component: "DepartmentsBar",
		hasDistributorData: true,
		title: "Departments",
		data: {
			sources: [
				{
					structuredData: "DEPARTMENTS",
				},
			],
			mode: "manual",
			fullRelations: true,
		},
	},
};

export default schema;
