import { QuoteTestimonialCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { detail, heading, verticalSpacing } from "@schemas/presets";

export const schema: Schema.Module<QuoteTestimonialCollectionProps> = {
	schemaType: "module",
	component: "QuoteTestimonialCollection",
	category: "collection",
	displayName: "QuoteTestimonialCollection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: false },
				{ ...detail },
				{ ...verticalSpacing },
				{
					type: "ComponentArray",
					title: "QuoteTestimonialCollection",
					key: "quoteTestimonial",
					contentType: "components",
					whiteList: ["NewQuoteTestimonial"],
				},
			],
		},
	],

	default: {
		component: "QuoteTestimonialCollection",
		detail: "Lorem ipsum",
		verticalSpacing: "medium",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		quoteTestimonial: [],
	},
};

export default schema;
