import { AccordionComponentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Component<AccordionComponentProps> = {
	schemaType: "component",
	component: "AccordionComponent",
	displayName: "AccordionComponent",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },
				{
					type: "ComponentArray",
					title: "Content",
					key: "content",
					contentType: "components",
					whiteList: [
						"InnerBasicContent",
						"InnerBasicText",
						"InnerModalLinkCollection",
						"InnerWysiwyg",
					],
				},
			],
		},
	],

	default: {
		component: "AccordionComponent",
		title: { content: "Lorem ipsum", tag: "h2" },
		content: [],
	},

	thumbnails: {
		"1x": "/thumbnails/components/AccordionComponent/thumbnail@1x.png",
		"2x": "/thumbnails/components/AccordionComponent/thumbnail@2x.png",
	},
};

export default schema;
