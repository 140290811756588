import { UIFields } from "@griddo/core";

export const defaultSubtheme = {
	value: "default",
	img: "/thumbnails/themes/default@2x.png",
};
export const defaultAltSubtheme = {
	value: "default-alt",
	img: "/thumbnails/themes/defaultAlt@2x.png",
};
export const inverseSubtheme = {
	value: "inverse",
	img: "/thumbnails/themes/inverse@2x.png",
};

export const themeSelector: UIFields.VisualUniqueSelection = {
	title: "Style",
	key: "subtheme",
	type: "VisualUniqueSelection",
	columns: 8,
	mandatory: true,
	options: [defaultSubtheme, defaultAltSubtheme, inverseSubtheme],
};
