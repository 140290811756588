import { FileItemProps } from "@autoTypes";
import { Schema } from "@griddo/core";
const schema: Schema.Component<FileItemProps> = {
	schemaType: "component",
	component: "FileItem",
	displayName: "File",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "File",
					type: "FileField",
					key: "file",
				},
			],
		},
	],

	default: {
		component: "FileItem",
	},
};

export default schema;
