import { ProgramListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { detail } from "@schemas/presets";

import ProgramListTabs from "../modules/ProgramListTabs";
import ProgramsDistributor from "../modules/ProgramsDistributor";

const schema: Schema.Template<ProgramListProps> = {
	dataPacks: ["PROGRAMS_PACK"],
	schemaType: "template",
	displayName: "Program List",
	component: "ProgramList",
	type: { label: "The items", value: "items" },

	content: [
		{ ...detail },

		{
			type: "ComponentArray",
			title: "Tabs section",
			whiteList: ["ProgramListTabs"],
			key: "tabsSection",
			contentType: "modules",
			maxItems: 1,
		},
	],

	default: {
		type: "template",
		templateType: "ProgramList",
		detail: "Lorem ipsum paragraph",
		tabsSection: {
			component: "Section",
			name: "Tabs Section",
			modules: [
				{
					...ProgramListTabs.default,
					component: "ProgramListTabs",
					elements: [
						{
							component: "ProgramListTabComponent",
							title: "All programs",
							sectionSlug: "/",
							relatedContent: [],
							listContent: [
								{
									...ProgramsDistributor.default,
									data: {
										mode: "auto",
										order: "alpha",
										quantity: 0,
										sources: [
											{
												structuredData: "PROGRAM",
											},
										],
									},
									title: null,
									detail: null,
									link: null,
								},
							],
						},
					],
				},
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProgramList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProgramList/thumbnail@2x.png",
	},
};

export default schema;
