import { ImageCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, image, link } from "@schemas/presets";

const schema: Schema.Component<ImageCardProps> = {
	schemaType: "component",
	component: "ImageCard",
	displayName: "Image Card",

	configTabs: [
		{
			title: "content",
			fields: [{ ...image }, { ...heading }, { ...link }],
		},
	],

	default: {
		component: "ImageCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		link: { component: "Button" },
		image: { component: "Image" },
	},

	thumbnails: {
		"1x": "/thumbnails/components/ImageCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/ImageCard/thumbnail@2x.png",
	},
};

export default schema;
