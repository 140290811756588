import AutoTypes from "@autoTypes";
import { Core, usePage } from "@griddo/core";
import { useCunefi18n } from "@hooks";
import { MainMenu, TopMenu } from "@ui/modules/Header/elements";
import { LanguagesProp } from "@ui/modules/Header/elements/LanguageSelector";
import { memo } from "react";

import * as styles from "./styles.module.css";

export type HeaderProps = AutoTypes.HeaderProps;

type LanguageType = {
	short: Record<Core.Locale, string>;
	long: Record<Core.Locale, string>;
};

function Header({
	form,
	subtheme,
	topMenu,
	menu,
	virtualOfficeNav,
	logo,
	typeCTABtn,
	link,
}: HeaderProps) {
	const { getNestedTranslation: t } = useCunefi18n();
	const languagesTranslation = t("languages") as LanguageType;
	const { pageLanguages, languageId, template, theme, headerTheme } = usePage();

	const stickyCls =
		template?.templateType === "ProgramDetail" ? "" : styles.sticky;

	const mappedLanguages = pageLanguages
		?.map(({ url, locale, languageId: localeId }) => {
			if (localeId !== languageId) {
				return {
					url,
					locale,
					languageId,
					translate: languagesTranslation?.short[locale],
				};
			}
			return null;
		})
		.filter(Boolean) as LanguagesProp;

	return (
		<header
			className={`${styles.header} ${stickyCls}`}
			data-theme={headerTheme || theme || undefined}
		>
			<TopMenu
				subtheme={subtheme}
				topMenu={topMenu}
				virtualOfficeNav={virtualOfficeNav}
				languageSelector={mappedLanguages}
			/>
			<MainMenu
				subtheme={subtheme}
				menu={menu}
				topMenu={topMenu}
				virtualOfficeNav={virtualOfficeNav}
				form={form}
				languageSelector={mappedLanguages}
				logo={logo}
				typeCTABtn={typeCTABtn}
				link={link}
			/>
		</header>
	);
}

export default memo(Header);
