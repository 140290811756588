import { useEffect, useState, RefObject } from "react";

interface Props {
	outerRef: RefObject<Element>;
	threshold: number;
	rootMargin: string;
	animateOnce?: boolean;
	animateBackwards?: boolean;
}

const useObserver = (props: Props) => {
	const { outerRef, threshold, rootMargin, animateOnce, animateBackwards } =
		props;
	const [inViewport, setInViewport] = useState(false);

	useEffect(() => {
		// Observes the target taking into account the threshold
		// and sets the state to true when it is in the viewport
		const onChange = (entries: Array<IntersectionObserverEntry>) => {
			entries.forEach((entry: IntersectionObserverEntry) => {
				if (entry.target === outerRef.current && entry.isIntersecting) {
					setInViewport(true);
				} else if (
					!animateOnce &&
					animateBackwards &&
					entry.target === outerRef.current &&
					(entry.boundingClientRect.y > 0 ||
						entry.boundingClientRect.bottom < 0)
				) {
					setInViewport(false);
				}
			});
		};
		const observer = new IntersectionObserver(onChange, {
			threshold,
			rootMargin,
		});
		outerRef.current && observer.observe(outerRef.current);

		// Observes the target regardless the threshold
		// and sets the state to false when it is under the bottom of the viewport
		if (!animateOnce) {
			const onChangeReset = (entries: Array<IntersectionObserverEntry>) => {
				entries.forEach((entry: IntersectionObserverEntry) => {
					if (
						entry.target === outerRef.current &&
						entry.boundingClientRect.y > window.innerHeight
					) {
						setInViewport(false);
					}
				});
			};
			const observerReset = new IntersectionObserver(onChangeReset);
			outerRef.current && observerReset.observe(outerRef.current);
		}
	}, [outerRef, animateBackwards, animateOnce, threshold]);

	return inViewport;
};

export { useObserver };
