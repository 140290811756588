import { PageLocaleContext } from "@contexts";
import { useI18n } from "@griddo/core";
import { useContext } from "react";

export function useCunefi18n() {
	const myContext = useContext(PageLocaleContext);

	const { getNestedTranslation, getTranslation } = useI18n({
		locale: myContext?.language || undefined,
	});

	return { getNestedTranslation, getTranslation };
}
