import { FeaturedAudiovisualProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, defaultLink, heading, link } from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<FeaturedAudiovisualProps> = {
	schemaType: "module",
	component: "FeaturedAudiovisual",
	category: "content",
	displayName: "Featured Audiovisual",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Visual Content 1",
					type: "ComponentArray",
					key: "visualContent1",
					whiteList: ["VideoComponent", "Image"],
					contentType: "components",
					maxItems: 1,
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514",
				},
				{
					title: "Visual Content 2",
					type: "ComponentArray",
					key: "visualContent2",
					whiteList: ["VideoComponent", "Image"],
					contentType: "components",
					maxItems: 1,
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514",
				},
				{
					...heading,
					mandatory: true,
				},
				{ ...richtext, title: "Content", key: "content", mandatory: true },
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],

	default: {
		component: "FeaturedAudiovisual",
		visualContent1: [
			{
				component: "VideoComponent",
			},
		],
		visualContent2: [
			{
				component: "VideoComponent",
			},
		],
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		link: {
			...defaultLink,
			text: "Saber más",
			variant: "text",
		},
		anchorID: null,
	},
	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedAudiovisual/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedAudiovisual/thumbnail@2x.png",
	},
};

export default schema;
