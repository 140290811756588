import { UIFields } from "@griddo/core";
import { MutablePreset } from "src/types";

export const richtext: MutablePreset<UIFields.RichText> = {
	type: "RichText",
	placeholder: "Type a text",
	hideable: true,
	isMockup: true,
	humanReadable: true,
};
