import { UIFields } from "@griddo/core";

export const heading: UIFields.HeadingField = {
	type: "HeadingField",
	title: "Title",
	default: { content: "", tag: "h2" },
	key: "title",
	placeholder: "Type a title",
	advanced: true,
	helptext: "Write plain text and select the heading type",
	hideable: true,
	isMockup: false,
	mandatory: true,
	humanReadable: true,
	options: [
		{ label: "h1", value: "h1" },
		{ label: "h2", value: "h2" },
		{ label: "h3", value: "h3" },
		{ label: "h4", value: "h4" },
		{ label: "h5", value: "h5" },
		{ label: "h6", value: "h6" },
	],
};
