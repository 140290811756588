import { LocationMapProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID } from "@schemas/presets";

import Location from "../components/LocationComponent";

const schema: Schema.Module<LocationMapProps> = {
	schemaType: "module",
	component: "LocationMap",
	category: "content",
	displayName: "Location Map",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Locations",
					key: "locations",
					contentType: "components",
					whiteList: ["Location"],
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],
	default: {
		component: "LocationMap",
		anchorID: null,
		locations: [{ ...Location.default }],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/LocationMap/thumbnail@1x.png",
		"2x": "/thumbnails/modules/LocationMap/thumbnail@2x.png",
	},
};

export default schema;
