import { ProgramNavigationTabProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<ProgramNavigationTabProps> = {
	schemaType: "component",
	component: "ProgramNavigationTab",
	displayName: "Program Tab",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "title",
					title: "Title",
					mandatory: true,
				},
				{
					type: "ComponentArray",
					title: "Content",
					key: "elements",
					contentType: "components",
					whiteList: [
						"AccordionCollection",
						"BasicContent",
						"BasicText",
						"CardCollection",
						"FeaturedBlock",
						"FeaturedContent",
						"HighlightQuote",
						"ProgramsDistributor",
						"NewsHighlightDistributor",
						"NewsSimpleDistributor",
						"PeopleDistributor",
						"QuoteTestimonialCollection",
						"ProgramQuickFacts",
						"TabsComponent",
						"Wysiwyg",
					],
				},
			],
		},
	],

	default: {
		component: "ProgramNavigationTab",
		title: "Lorem ipsum",
		elements: [],
	},

	thumbnails: {
		"1x": "/thumbnails/components/ProgramNavigationTab/thumbnail@1x.png",
		"2x": "/thumbnails/components/ProgramNavigationTab/thumbnail@2x.png",
	},
};

export default schema;
