import { LandingIntroProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, heading, verticalSpacing, wysiwyg } from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<LandingIntroProps> = {
	schemaType: "module",
	component: "LandingIntro",
	displayName: "LandingIntro",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, title: "Title", key: "title", mandatory: false },
				{ ...richtext, title: "Subtitle", key: "subtitle" },
				{ ...wysiwyg, title: "Content", key: "content" },
				{
					type: "ComponentArray",
					key: "quickFactsElements",
					title: "QuickFacts",
					contentType: "components",
					whiteList: ["HeroQuickfactComponent"],
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "LandingIntro",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		quickFactsElements: [],
		detail: "Lorem ipsum paragraph",
		subtheme: "default",
		subtitle: "Lorem ipsum",
		content: "Content",
		verticalSpacing: "medium",
	},
};

export default schema;
