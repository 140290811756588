import { ModalLinkCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	richtext,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ModalLinkCollectionProps> = {
	schemaType: "module",
	component: "ModalLinkCollection",
	category: "collection",
	displayName: "Modal Link Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },
				{ ...richtext, title: "Subtitle", key: "subtitle" },
				{
					type: "ConditionalField",
					title: "Column 1",
					key: "c1",
					mandatory: true,
					options: [
						{ title: "withImage", name: "withImage", value: "withImage" },
						{ title: "withText", name: "withText", value: "withText" },
					],
					fields: [
						{
							type: "ComponentArray",
							title: "Column 1",
							key: "c1ImageElements",
							whiteList: ["Image"],
							contentType: "components",
							condition: "withImage",
							maxItems: 1,
						},
						{
							type: "ComponentArray",
							title: "Column 1",
							key: "c1LinkCardsElements",
							whiteList: ["ModalLinkCard"],
							contentType: "components",
							condition: "withText",
						},
					],
				},

				{
					type: "ConditionalField",
					title: "Column 2",
					key: "c2",
					options: [
						{ title: "empty", name: "empty", value: "empty" },
						{ title: "withImage", name: "withImage", value: "withImage" },
						{ title: "withText", name: "withText", value: "withText" },
					],
					fields: [
						{
							type: "ComponentArray",
							title: "Column 2",
							key: "c2ImageElements",
							whiteList: ["Image"],
							contentType: "components",
							condition: "withImage",
							maxItems: 1,
						},
						{
							type: "ComponentArray",
							title: "Column 2",
							key: "c2LinkCardsElements",
							whiteList: ["ModalLinkCard"],
							contentType: "components",
							condition: "withText",
						},
					],
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "ModalLinkCollection",
		c1: "withText",
		c2: "empty",
		c1ImageElements: [],
		c1LinkCardsElements: [
			{
				component: "ModalLinkCard",
				link: "Link",
				modalContent: "ModalContent",
				modalSubtitle: "ModalSubtitle",
				modalTitle: "ModalTitle",
				title: "Title",
			},
		],
		c2ImageElements: [],
		c2LinkCardsElements: [],
		anchorID: null,
		image: { component: "Image" },
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ModalLinkCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ModalLinkCollection/thumbnail@2x.png",
	},
};

export default schema;
