import { OptionProps } from "@ui/elements/Select";

export function useMountFormOptions(
	options: Record<string, string>,
	values: Record<string, string>
) {
	const _options: Array<OptionProps> = [];

	Object.keys(options).forEach((key: string) => {
		_options.push({
			label: options[key] as string,
			value: values[key],
		});
	});

	return _options;
}
