import {
	DATALAYER_CUSTOM_EVENTS,
	PAGE_LANGUAGE,
	TEMPLATES_TYPES,
} from "@customTypes/dataLayer";
import { mountParamsPage } from "@helpers";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const generateAutomaticDimensions = (page: any) => {
	const {
		pageContext: {
			locale,
			page: {
				template: { templateType },
				isHome,
				fullPath,
				dimensions,
				site,
				degreeType,
			},
		},
	} = page;

	const pageData = page?.pageContext?.page;

	const _dimensions = dimensions instanceof Object ? dimensions : {};

	const dataLayer = mountParamsPage({
		pageLanguage:
			locale === "es-ES" || locale === "en-GB"
				? PAGE_LANGUAGE[locale]
				: PAGE_LANGUAGE["es-ES"],
		isHome,
		templateType,
		pathname: fullPath?.page,
		eventName: DATALAYER_CUSTOM_EVENTS.PAGE_VIEW,
		site: site,
	});

	if (templateType === TEMPLATES_TYPES.PROGRAM_DETAIL) {
		const {
			title,
			template: { programType, crmId, areas },
		} = pageData;
		dataLayer.study_type = programType?.length > 0 ? programType[0]?.label : "";
		dataLayer.product_id = crmId;
		dataLayer.product_name = title;
		dataLayer.product_area = areas?.length > 0 ? areas[0]?.title : "";
	} else if (templateType === TEMPLATES_TYPES.PROFESSOR_DETAIL) {
		const {
			template: { departments },
		} = pageData;
		dataLayer.staff_department =
			departments?.length > 0 ? departments[0]?.title : "";
	} else if (templateType === TEMPLATES_TYPES.NEWS_DETAIL) {
		const {
			template: { newsCategory },
		} = pageData;
		dataLayer.news_category =
			newsCategory?.length > 0 ? newsCategory[0]?.title : "";
	}

	if (degreeType) {
		dataLayer.degree_type = degreeType;
	}

	if (_dimensions["section_type"]) {
		dataLayer.section_type = _dimensions["section_type"];
	}

	return dataLayer;
};
