import { OdsCardRowsProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<OdsCardRowsProps> = {
	schemaType: "component",
	displayName: "OdsCardRows",
	component: "OdsCardRows",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "ODS Cards",
					key: "elements",
					whiteList: ["OdsCard"],
					contentType: "components",
					mandatory: true,
					maxItems: 3,
				},
			],
		},
	],

	default: {
		title: "Ods Card",
		component: "OdsCardRows",
		elements: [
			{
				component: "OdsCard",
				title: { content: "Lorem ipsum", tag: "h2" },
				content: "Subtitle",
				number: 0,
				prefix: null,
				suffix: null,
				alignIcon: "left",
			},
		],
	},
};

export default schema;
