import { ChartProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, richtext } from "@schemas/presets";

const schema: Schema.Component<ChartProps> = {
	schemaType: "component",
	displayName: "Chart",
	component: "Chart",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					title: "Chart Name",
					default: { tag: "h2", content: "Title" },
				},
				{ ...richtext, title: "Description", key: "description" },
				{
					title: "Chart Style",
					key: "kind",
					type: "VisualUniqueSelection",
					columns: 8,
					mandatory: true,
					options: [
						{
							value: "circle",
							img: "/thumbnails/components/Chart/Layouts/circle.png",
						},
						{
							value: "semicircle",
							img: "/thumbnails/components/Chart/Layouts/semicircle.png",
						},
						{
							value: "radial",
							img: "/thumbnails/components/Chart/Layouts/radial.png",
						},
						{
							value: "polar",
							img: "/thumbnails/components/Chart/Layouts/polar.png",
						},
						{
							value: "horizontal-bars",
							img: "/thumbnails/components/Chart/Layouts/horizontal-bars.png",
						},
						{
							value: "vertical-bars",
							img: "/thumbnails/components/Chart/Layouts/vertical-bars.png",
						},
					],
				},
				{
					title: "Unit",
					type: "TextField",
					key: "unit",
					mandatory: true,
				},
				{
					type: "ArrayFieldGroup",
					arrayType: "dropDown",
					title: "Data",
					key: "chartData",
					name: "Data",
					fields: [
						{
							title: "Label",
							type: "TextField",
							key: "label",
							mandatory: true,
							isTitle: true,
							humanReadable: true,
						},
						{
							title: "Value",
							type: "NumberField",
							key: "value",
							mandatory: true,
						},
						{
							title: "Color",
							type: "ColorPicker",
							key: "color",
							mandatory: true,
							colors: [
								"#FF5700",
								"#FE961F",
								"#1A1F6C",
								"#5F639C",
								"#D2E0F3",
								"#D6D1C4",
								"#F0ECE8",
								"#0796A3",
								"#AE3C7C",
								"#000000",
							],
						},
					],
				},
				{
					title: "Alternative text",
					type: "TextField",
					key: "alt",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "Chart",
		kind: "circle",
		title: { content: "Chart Title", tag: "h2" },
		unit: "%",
		chartData: [
			{
				label: "Item 1",
				value: 40,
				color: "#FF5700",
			},
			{
				label: "Item 2",
				value: 40,
				color: "#F0ECE8",
			},
			{
				label: "Item 3",
				value: 20,
				color: "#1A1F6C",
			},
		],
		alt: "Chart description",
	},

	thumbnails: {
		"1x": "/thumbnails/components/Chart/thumbnail@1x.png",
		"2x": "/thumbnails/components/Chart/thumbnail@2x.png",
	},
};

export default schema;
