//
//
//
//
//
// TODO: Cambiar por urls de CUNEF
//
//
//
//
//

export const IMAGE = {
	mock: "https://images.dev.griddo.io/photo-1538819137474-ffa0ee381af6_1",
	mock2: "https://images.dev.griddo.io/maciek-sulkowski-z-j6t9ujqeg-unsplash",
	mock3: "https://images.griddo.secuoyas.com/photo-1590477331415-ec493bfa6414",
	mock4: "https://d1t8hbw55lfxhb.cloudfront.net/cunef",
	gif2: "https://images.griddo.secuoyas.com/minion-animado",
	gif: "https://images.griddo.secuoyas.com/griddo-css-vscode-alpha",
	logoCollectionLogo1: "https://d1t8hbw55lfxhb.cloudfront.net/logo-kpmg",
	logoCollectionLogo2: "https://d1t8hbw55lfxhb.cloudfront.net/logo-pwc",
	logoCollectionLogo3: "https://d1t8hbw55lfxhb.cloudfront.net/logo-denoite",
	logoCollectionLogo4: "https://d1t8hbw55lfxhb.cloudfront.net/logo-ey",
	logoCollectionLogo5: "https://d1t8hbw55lfxhb.cloudfront.net/logo-ey",
};

export const VIDEO = {
	youtube: "https://www.youtube.com/watch?v=2Gg6Seob5Mg",
	youtube2: "https://www.youtube.com/watch?v=gCWaRhNUvfc",
	vimeo: "https://vimeo.com/21611026",
	vimeo2: "https://vimeo.com/112836958",
};
