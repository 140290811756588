import { UIFields } from "@griddo/core";
import { MutablePreset } from "src/types";

export const wysiwyg: MutablePreset<UIFields.Wysiwyg> = {
	type: "Wysiwyg",
	placeholder: "Type a text",
	hideable: true,
	isMockup: true,
	humanReadable: true,
};
