import { Core, useGriddoImage, UseGriddoImageProps } from "@griddo/core";

export interface UseBgImageProps extends UseGriddoImageProps {
	/** formato obligatorio */
	format?: Core.ImageFormats;
}

export function useBgImage(props: UseBgImageProps) {
	// This line prevents that responsive get mutated from useGriddoImage
	const localResponsive = [
		...(props.responsive || ([] as Array<Core.ResponsiveImageProps>)),
	];

	const griddoImageOutput = useGriddoImage(props);

	// SHAME: Sorry, reduce here! :)
	return localResponsive.reduce(
		(ACC: { [key: string]: string }, CURR, index) => {
			if (CURR.breakpoint !== null) {
				ACC[CURR.breakpoint as string] = `url(${
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					griddoImageOutput[props.format || "jpg"]?.srcSetURL[index]
				})`;
			}
			return ACC;
		},
		{}
	);
}
