// TODO: Crear alias para el archivo de types o importarlo en el index
import { ProgramDetailProps } from "@autoTypes";
import {
	DATALAYER_CUSTOM_EVENTS,
	DatalayerCustomEvent,
	DataLayerCustomEventsExtraParams,
	DataLayerDownloadFileExtraParams,
	EcommerceProps,
	FORM_ID,
	ITEM_LIST_NAME,
	PAGE_LANGUAGE,
	PAGES_TYPES,
	PREFIX_PAGE_NAME,
	SECTION_TYPE,
	SITES,
	STUDY_TYPE,
	TEMPLATES_TYPES,
} from "@customTypes/dataLayer";

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		dataLayer: Array<Record<string, any>>;
	}
}

export const addDataLayer = (
	event:
		| DatalayerCustomEvent
		| DataLayerCustomEventsExtraParams
		| DataLayerDownloadFileExtraParams
) => {
	if (typeof window === "undefined") {
		return;
	}
	if (!window.dataLayer) {
		window.dataLayer = [];
	}
	window.dataLayer.push(event);
};

const getSiteById = (siteID: number): SITES => {
	switch (siteID) {
		case 30:
			return SITES.CUNEF;
		case 31:
			return SITES.CES;
		default:
			return SITES.CUNEF;
	}
};

type DataLayerMasterProps = {
	pageLanguage: PAGE_LANGUAGE;
	eventName: DATALAYER_CUSTOM_EVENTS;
	site: number;
	degreeType?: "OFFICIAL" | "CUSTOM";
};

export const DataLayerMaster = ({
	pageLanguage,
	eventName = DATALAYER_CUSTOM_EVENTS.PAGE_VIEW,
	site,
	degreeType,
}: DataLayerMasterProps) => {
	const result: DatalayerCustomEvent = {
		event: "custom_event",
		event_name: eventName,
		page_language: pageLanguage,
		site_name: getSiteById(site),
		...(degreeType && { degree_type: degreeType }),
	};

	return result;
};

type mountParamsPageProps = {
	pageLanguage: PAGE_LANGUAGE;
	isHome: boolean;
	templateType: string;
	pathname: string;
	eventName: DATALAYER_CUSTOM_EVENTS;
	site: number;
	degreeType?: "OFFICIAL" | "CUSTOM";
};

export const mountParamsPage = ({
	pageLanguage,
	isHome = false,
	templateType,
	pathname,
	eventName,
	site,
	degreeType,
}: mountParamsPageProps) => {
	const result = DataLayerMaster({ pageLanguage, eventName, site, degreeType });

	if (isHome) {
		result.page_type = PAGES_TYPES.HOME;
		result.section_type = SECTION_TYPE.HOME;
		result.page_name = PAGES_TYPES.HOME;
	} else {
		switch (templateType) {
			case TEMPLATES_TYPES.ERROR_404:
				result.page_type = PAGES_TYPES.ERROR_404;
				break;

			case TEMPLATES_TYPES.LANDING_PROGRAM:
				result.page_type = PAGES_TYPES.LANDING;
				result.section_type = SECTION_TYPE.LANDING;
				break;

			case TEMPLATES_TYPES.LEGAL_TEMPLATE:
				result.page_type = PAGES_TYPES.LEGAL;
				break;

			case TEMPLATES_TYPES.NEWS_DETAIL:
				result.page_type = PAGES_TYPES.NEWS;
				result.section_type = SECTION_TYPE.NEWS;
				result.page_name = `${PREFIX_PAGE_NAME.NEWS}${pathname}`;
				break;

			case TEMPLATES_TYPES.NEWS_LIST:
				result.page_type = PAGES_TYPES.NEWS_LIST;
				result.section_type = SECTION_TYPE.NEWS;
				result.page_name = `${PREFIX_PAGE_NAME.NEWS}${pathname}`;
				break;

			case TEMPLATES_TYPES.PROFESSOR_DETAIL:
				result.page_type = PAGES_TYPES.STAFF;
				result.section_type = SECTION_TYPE.FACULTY_AND_RESEARCH;
				result.page_name = `${PREFIX_PAGE_NAME.STAFF}${pathname}`;
				break;

			case TEMPLATES_TYPES.PROFESSOR_LIST:
				result.page_type = PAGES_TYPES.STAFF_LIST;
				result.section_type = SECTION_TYPE.FACULTY_AND_RESEARCH;
				result.page_name = `${PREFIX_PAGE_NAME.STAFF}${pathname}`;
				break;

			case TEMPLATES_TYPES.PROGRAM_DETAIL:
				result.page_type = PAGES_TYPES.STUDY;
				result.section_type = SECTION_TYPE.STUDIES;
				result.page_name = `${PREFIX_PAGE_NAME.STUDY}${pathname}`;
				break;

			case TEMPLATES_TYPES.PROGRAM_LIST:
				result.page_type = PAGES_TYPES.STUDY_LIST;
				result.section_type = SECTION_TYPE.STUDIES;
				result.page_name = `${PREFIX_PAGE_NAME.STUDY}${pathname}`;
				break;

			default:
				result.page_type = PAGES_TYPES.CONTENT;
				break;
		}
	}

	return result;
};

export type mountDataLayerProductProps = {
	pageLanguage: PAGE_LANGUAGE;
	eventName: DATALAYER_CUSTOM_EVENTS;
	ecommerce?: {
		items: Array<EcommerceProps>;
	};
	site: number;
	title?: string;
	crmId: ProgramDetailProps["crmId"];
	sendEcommerce?: boolean;
	formId?: FORM_ID;
	itemCategory2?: STUDY_TYPE;
	areas?: ProgramDetailProps["areas"] | Array<{ label: string }>;
	itemListName?: ITEM_LIST_NAME;
	lead_id_CRM?: string;
	studyType?: STUDY_TYPE;
	degreeType?: "OFFICIAL" | "CUSTOM";
	programType?: ProgramDetailProps["programType"];
	transaction_id?: string;
};

type mountDataLayerFileProps = {
	pageLanguage: PAGE_LANGUAGE;
	eventName: DATALAYER_CUSTOM_EVENTS;
	site: number;
	file_name?: string;
	file_extension?: string;
};

export const mountDataLayerProduct = ({
	pageLanguage,
	eventName,
	site,
	title,
	areas,
	crmId,
	formId,
	studyType,
	degreeType,
	lead_id_CRM,
	itemListName,
	programType,
	transaction_id,
	ecommerce,
	sendEcommerce = true,
}: mountDataLayerProductProps) => {
	const result: DataLayerCustomEventsExtraParams = DataLayerMaster({
		pageLanguage,
		eventName,
		site,
		degreeType,
	});

	const _areas =
		(areas && areas?.length > 0 && (areas[0]?.title || areas[0]?.label)) || "";

	if (crmId) {
		result.product_id = crmId;
	}
	result.product_name = title;
	if (_areas !== "") {
		result.product_area = _areas;
	}

	if (lead_id_CRM) {
		result.lead_id_crm = lead_id_CRM;
	}
	if (transaction_id) {
		result.transaction_id = transaction_id;
	}

	if (formId) {
		result.form_id = formId;
	}

	if (programType && Array.isArray(programType)) {
		result.programType = programType[0]?.label || programType[0]?.title || "";
	} else {
		result.programType = programType;
	}

	if (studyType) {
		result.studyType = studyType;
	}

	if (sendEcommerce) {
		result.ecommerce = {
			items: [],
		};
		if (!ecommerce) {
			result.ecommerce?.items.push({
				...(itemListName && { item_list_name: itemListName }),
				item_name: title,
				item_id: crmId,
				item_category: _areas,
				item_category_2: (programType && programType[0]?.title) || "",
				quantity: 1,
				price: 0,
				currency: "€",
			} as EcommerceProps);
		} else {
			result.ecommerce = ecommerce;
		}
	}

	addDataLayer(result);
};
export const mountOpenFormDataLayer = ({
	pageLanguage,
	eventName,
	site,
	crmId,
}: mountDataLayerProductProps) => {
	const result: DataLayerCustomEventsExtraParams = DataLayerMaster({
		pageLanguage,
		eventName,
		site,
	});

	result.product_id = crmId;

	addDataLayer(result);
};

export const mountDataLayerFileDownLoad = ({
	pageLanguage,
	eventName,
	site,
	file_name,
	file_extension,
}: mountDataLayerFileProps) => {
	const result: DataLayerCustomEventsExtraParams = DataLayerMaster({
		pageLanguage,
		eventName,
		site,
	});

	result.file_name = file_name;
	result.file_extension = file_extension;

	addDataLayer(result);
};
