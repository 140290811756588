import { HeroCarouselProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID } from "@schemas/presets";

import HeroSlide from "../components/HeroSlide";

const schema: Schema.Module<HeroCarouselProps> = {
	schemaType: "module",
	component: "HeroCarousel",
	category: "hero",
	displayName: "HeroCarousel",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Slides",
					key: "slides",
					whiteList: ["HeroSlide"],
					contentType: "components",
					maxItems: 6,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],

	default: {
		component: "HeroCarousel",
		slides: [
			{
				...HeroSlide.default,
			},
		],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeroCarousel/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroCarousel/thumbnail@2x.png",
	},
};

export default schema;
