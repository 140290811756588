import { Schema } from "@griddo/core";

export const DEPARTMENTS: Schema.CategoryContentType = {
	dataPacks: ["PROFESSORS_PACK"],
	title: "Departments (Auto)",
	local: true,
	translate: true,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
