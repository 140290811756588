import { HeroProgramProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, heading, image, link } from "@schemas/presets";

const schema: Schema.Module<HeroProgramProps> = {
	schemaType: "module",
	component: "HeroProgram",
	category: "hero",
	displayName: "HeroProgram",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...image },
				{
					type: "TextField",
					key: "subtitle",
					title: "Subtitle",
					hideable: true,
				},
				{ ...heading, hideable: false },
				{
					type: "TextArea",
					key: "intro",
					title: "Intro",
					hideable: true,
				},
				{ ...link, hideable: true },
				{
					type: "ComponentArray",
					key: "quickFactsElements",
					title: "QuickFacts",
					contentType: "components",
					whiteList: ["HeroQuickfactComponent"],
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],

	default: {
		component: "HeroProgram",
		image: {
			component: "Image",
			fetchpriority: "high",
			loading: "eager",
			decoding: "sync",
		},
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: "Lorem ipsum paragraph",
		intro: "Lorem ipsum paragraph",
		link: {
			component: "Button",
		},
		quickFactsElements: [],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeroProgram/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroProgram/thumbnail@2x.png",
	},
};

export default schema;
