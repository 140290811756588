import { FeaturedBlockProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	heading,
	image,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<FeaturedBlockProps> = {
	schemaType: "module",
	component: "FeaturedBlock",
	category: "content",
	displayName: "Featured Block",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...image,
					title: "Icon",
					key: "icon",
					hideable: true,
				},
				{
					...heading,
					mandatory: true,
				},
				{ ...richtext, title: "Content", key: "content", mandatory: true },
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "FeaturedBlock",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		link: {
			...defaultLink,
			text: "Saber más",
			variant: "secondary",
		},
		icon: {
			component: "Image",
		},
		subtheme: "default-alt",
		anchorID: null,
		verticalSpacing: "large",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedBlock/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedBlock/thumbnail@2x.png",
	},
};

export default schema;
