import { FilesProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { fileContainer } from "@schemas/presets";
const schema: Schema.Module<FilesProps> = {
	schemaType: "module",
	component: "Files",
	category: "content",
	displayName: "Files",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Note",
					key: "noteGeneral",
					type: "NoteField",
					value: {
						title: "Files for text editor",
						text: "This field will not be visible to the user. Here you have to add any files (not images) that you want to link in any text on this page (or any page).",
					},
				},
				{ ...fileContainer, title: "files" },
			],
		},
	],

	default: {
		component: "Files",
		title: "hola",
		files: [],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Files/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Files/thumbnail@2x.png",
	},
};

export default schema;
