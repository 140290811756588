import { LogoCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { IMAGE } from "@mockupdefaults";
import {
	anchorID,
	detail,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<LogoCollectionProps> = {
	schemaType: "module",
	component: "LogoCollection",
	category: "content",
	displayName: "Logo Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				// BasicIntro
				{ ...heading },
				{ ...detail },
				{
					title: "Logos",
					type: "ComponentArray",
					key: "logos",
					whiteList: ["Image"],
					contentType: "components",
					mandatory: true,
					helptext: "SVG vector files recommended",
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],
	default: {
		component: "LogoCollection",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		logos: [
			{
				component: "Image",
				alt: "texto alternativo",
				imageField: {
					url: IMAGE.logoCollectionLogo1,
				},
			},
			{
				component: "Image",
				alt: "texto alternativo",
				imageField: {
					url: IMAGE.logoCollectionLogo2,
				},
			},
			{
				component: "Image",
				alt: "texto alternativo",
				imageField: {
					url: IMAGE.logoCollectionLogo3,
				},
			},
			{
				component: "Image",
				alt: "texto alternativo",
				imageField: {
					url: IMAGE.logoCollectionLogo4,
				},
			},
			{
				component: "Image",
				alt: "texto alternativo",
				imageField: {
					url: IMAGE.logoCollectionLogo5,
				},
			},
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/LogoCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/LogoCollection/thumbnail@2x.png",
	},
};

export default schema;
