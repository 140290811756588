import { IntroFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<IntroFormProps> = {
	schemaType: "module",
	component: "IntroForm",
	category: "content",
	displayName: "Intro Form",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Subtitle",
					key: "subtitle",
					type: "TextField",
					placeholder: "Type a text",
					hideable: true,
					isMockup: true,
				},

				{
					...heading,
					hideable: false,
				},

				{
					...richtext,
					title: "Detail",
					key: "detail",
				},

				{ ...link, hideable: true },

				{
					type: "ComponentArray",
					title: "Feature",
					key: "elements",
					whiteList: ["FeatureComponent"],
					contentType: "components",
					maxItems: 3,
				},

				{
					type: "ReferenceField",
					title: "Programs",
					sources: [{ structuredData: "PROGRAM" }],
					selectionType: ["auto", "manual"],
					key: "data",
				},

				{
					type: "ComponentContainer",
					title: "form",
					key: "form",
					whiteList: ["BasicForm"],
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "IntroForm",
		subtitle: "Lorem ipsum",
		title: {
			content: "Lorem ipsum",
			tag: "h3",
		},
		detail: "Lorem ipsum paragraph",
		elements: [
			{
				component: "FeatureComponent",
				title: "Feature 1",
				description: "Lorem ipsum paragraph",
				icon: { component: "Image" },
			},
			{
				component: "FeatureComponent",
				title: "Feature 2",
				description: "Lorem ipsum paragraph",
				icon: { component: "Image" },
			},
			{
				component: "FeatureComponent",
				title: "Feature 3",
				description: "Lorem ipsum paragraph",
				icon: { component: "Image" },
			},
		],
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "alpha",
			quantity: 0,
			sources: [
				{
					structuredData: "PROGRAM",
				},
			],
		},
		form: {
			component: "BasicForm",
			title: {
				content: "Lorem ipsum",
				tag: "h3",
			},
			content: "Lorem ipsum paragraph",
			buttonLabel: "Enviar",
		},
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default-alt",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/IntroForm/thumbnail@1x.png",
		"2x": "/thumbnails/modules/IntroForm/thumbnail@2x.png",
	},
};

export default schema;
