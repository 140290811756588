import { ProgramListTabComponentProps } from "@autoTypes";
import { Schema } from "@griddo/core";

import ProgramsDistributor from "../modules/ProgramsDistributor";

const schema: Schema.MultiPageComponent<ProgramListTabComponentProps> = {
	schemaType: "component",
	component: "ProgramListTabComponent",
	displayName: "Tab (as page)",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "List Content",
					whiteList: ["ProgramsDistributor"],
					key: "listContent",
					contentType: "components",
					maxItems: 1,
				},

				{
					type: "ComponentArray",
					title: "Related Content",
					whiteList: ["ProgramsDistributor"],
					key: "relatedContent",
					contentType: "components",
					maxItems: 1,
				},
			],
		},
		{
			title: "Seo",
			fields: [
				{
					title: "Page title",
					key: "title",
					type: "TextField",
				},

				{
					title: "Section Slug",
					key: "sectionSlug",
					type: "TextField",
				},

				{
					title: "Meta title",
					key: "metaTitle",
					type: "TextField",
				},

				{
					title: "Meta description",
					key: "metaDescription",
					type: "TextField",
				},
			],
		},
	],

	default: {
		component: "ProgramListTabComponent",
		title: "Tab title",
		sectionSlug: "tab-01",
		metaTitle: "The meta title",
		metaDescription: "The meta description",
		hasDistributorData: true,
		listContent: [
			{
				...ProgramsDistributor.default,
				data: {
					mode: "auto",
					order: "alpha",
					quantity: 0,
					sources: [{ structuredData: "PROGRAM" }],
				},
				title: null,
				detail: null,
				link: null,
			},
		],
		relatedContent: [],
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProgramListTabComponent/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProgramListTabComponent/thumbnail@2x.png",
	},
};

export default schema;
