import { ProfessorListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { detail } from "@schemas/presets";

const schema: Schema.Template<ProfessorListProps> = {
	dataPacks: ["PROFESSORS_PACK"],
	schemaType: "template",
	displayName: "ProfessorList",
	component: "ProfessorList",
	type: { label: "The items", value: "items" },

	content: [
		{ ...detail },

		{
			type: "TextField",
			key: "noResultsText",
			title: "No result text",
			mandatory: true,
		},

		{
			type: "ReferenceField",
			title: "Members",
			sources: [{ structuredData: "PROFESSORS" }],
			selectionType: ["auto", "manual"],
			key: "data",
		},
		{
			type: "ComponentContainer",
			key: "departments",
			title: "Departments",
			whiteList: ["DepartmentsBar"],
		},
	],

	default: {
		type: "template",
		templateType: "ProfessorList",
		hasDistributorData: true,
		detail: "Lorem ipsum paragraph",
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		data: {
			sources: [
				{
					structuredData: "PROFESSORS",
				},
			],
			mode: "auto",
			/* ---------- */
			/* opcionales */
			/* ---------- */
			// order: 'alpha-ASC',
			// filter: ['string'],
			fullRelations: true,
			// quantity: 2
		},
		departments: {
			component: "DepartmentsBar",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProfessorList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProfessorList/thumbnail@2x.png",
	},
};

export default schema;
