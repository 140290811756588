import { AREAS } from "./AREAS";
import { LANGUAGE } from "./LANGUAGE";
import { ODS } from "./ODS";
import { PROGRAM_TYPE } from "./PROGRAM_TYPE";
import { QUICK_FACTS } from "./QUICK_FACTS";

export default {
	AREAS,
	LANGUAGE,
	ODS,
	PROGRAM_TYPE,
	QUICK_FACTS,
};
