import { BasicCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, image } from "@schemas/presets";
import { button } from "@schemas/presets/button";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<BasicCardProps> = {
	schemaType: "component",
	component: "BasicCard",
	displayName: "BasicCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...image },
				{ ...heading },
				{
					...richtext,
					title: "Subtitle",
					key: "subtitle",
				},
				{
					...richtext,
					title: "Detail",
					key: "detail",
				},
				{ ...button },
			],
		},
	],

	default: {
		component: "BasicCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: "Subtitle",
		detail: "Detail",
		link: { component: "Button" },
		image: { component: "Image" },
	},

	thumbnails: {
		"1x": "/thumbnails/components/BasicCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/BasicCard/thumbnail@2x.png",
	},
};

export default schema;
