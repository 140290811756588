import { BasicHeroProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, detail, heading } from "@schemas/presets";

const schema: Schema.Module<BasicHeroProps> = {
	schemaType: "module",
	component: "BasicHero",
	category: "hero",
	displayName: "Basic Hero",

	configTabs: [
		{
			title: "content",
			fields: [{ ...heading }, { ...detail }],
		},
		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],

	default: {
		component: "BasicHero",
		title: {
			content: "Lorem ipsum",
			tag: "h1",
		},
		detail: "Detail",
		anchorID: null,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicHero/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicHero/thumbnail@2x.png",
	},
};

export default schema;
