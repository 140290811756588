import { LandingProgramNavigationProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Module<LandingProgramNavigationProps> = {
	schemaType: "module",
	component: "LandingProgramNavigation",
	category: "content",
	displayName: "LandingProgramNavigation",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Tabs",
					key: "programTabs",
					whiteList: ["LandingProgramNavigationTab"],
					contentType: "components",
				},
				{
					type: "ComponentContainer",
					title: "Intro Form",
					key: "landingForm",
					whiteList: ["BasicIntroForm"],
				},
			],
		},
	],

	default: {
		component: "LandingProgramNavigation",
		programTabs: [
			{
				component: "LandingProgramNavigationTab",
				title: "",
				elements: [],
			},
		],
		landingForm: {
			component: "BasicIntroForm",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramNavigation/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramNavigation/thumbnail@2x.png",
	},
};

export default schema;
