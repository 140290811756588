import { FeatureComponentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { image } from "@schemas/presets";

const schema: Schema.Component<FeatureComponentProps> = {
	schemaType: "component",
	component: "FeatureComponent",
	displayName: "FeatureComponent",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...image,
					title: "Icon",
					key: "icon",
					hideable: true,
				},
				{
					title: "Title",
					key: "title",
					type: "TextField",
					placeholder: "Type a text",
					hideable: true,
				},
				{
					title: "Description",
					key: "description",
					type: "TextArea",
					placeholder: "Type a text",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "FeatureComponent",
		title: "Feature",
		description: "Lorem ipsum paragraph",
		image: { component: "Image" },
	},

	thumbnails: {},
};

export default schema;
