import { SocialShareProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, themeSelector, verticalSpacing } from "@schemas/presets";

const schema: Schema.Module<SocialShareProps> = {
	schemaType: "module",
	component: "SocialShare",
	category: "content",
	displayName: "SocialShare",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "CheckGroup",
					key: "socialMedia",
					title: "Social Media",
					options: [
						{ name: "facebook", title: "Facebook" },
						{ name: "twitter", title: "Twitter" },
						{ name: "linkedin", title: "Linkedin" },
					],
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "SocialShare",
		subtheme: "default",
		anchorID: null,
		verticalSpacing: "medium",
		socialMedia: undefined,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/SocialShare/thumbnail@1x.png",
		"2x": "/thumbnails/modules/SocialShare/thumbnail@2x.png",
	},
};

export default schema;
