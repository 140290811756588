import { ProgramNavigationProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Module<ProgramNavigationProps> = {
	schemaType: "module",
	component: "ProgramNavigation",
	category: "content",
	displayName: "ProgramNavigation",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Tabs",
					key: "programTabs",
					whiteList: ["ProgramNavigationTab"],
					contentType: "components",
				},
				{
					type: "TextField",
					title: "Start Admission URL",
					key: "startAdmission",
					placeholder: "Admission external URL",
					validators: {
						format: "URL",
					},
				},
				{
					type: "TextField",
					title: "Brochure Title",
					placeholder: "Type brochure title",
					key: "brochureTitle",
					hideable: true,
				},
				{
					type: "TextField",
					title: "Brochure URL",
					key: "brochureUrl",
					placeholder: "Type brochure URL",
					validators: {
						format: "URL",
					},
				},

				{
					type: "ComponentContainer",
					title: "Form",
					key: "form",
					whiteList: ["BasicForm"],
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "ProgramNavigation",
		form: {
			component: "BasicForm",
			title: {
				content: "Lorem ipsum",
				tag: "h3",
			},
			content: "Lorem ipsum paragraph",
			buttonLabel: "Descargar folleto",
		},
		startAdmission: "https://www.cunef.edu/",
		brochureTitle: "",
		brochureUrl: "",
		programTabs: [
			{
				component: "ProgramNavigationTab",
				title: "",
				elements: [],
			},
		],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramNavigation/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramNavigation/thumbnail@2x.png",
	},
};

export default schema;
