import { Schema } from "@griddo/core";

export const QUICK_FACTS: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS_PACK"],
	title: "Quick Facts",
	local: true,
	translate: true,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Quick Fact",
				type: "TextField",
				mandatory: true,
				helptext: "Identifying text for the Quick Fact",
			},
			{
				key: "label",
				title: "Quick Fact",
				type: "TextField",
				mandatory: true,
				humanReadable: true,
			},
			{
				key: "icon",
				title: "Quick fact",
				type: "ImageField",
			},
		],
	},
};
