export const idFromYoutubeUrl = (url: string) => {
	const removeParams = url?.split("&")[0];
	const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/;
	const match = removeParams?.match(regExp);
	if (match && match[2].length === 11) {
		return match[2];
	}
	return null;
};

export const idFromVimeoUrl = (url: string) => {
	const regExp =
		/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/[^]*\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i;
	const match = url?.match(regExp);
	if (match && match[1]) {
		return match[1];
	}
	return null;
};

export const youtubeThumbnailFromUrl = (
	url: string,
	size: "max" | "standard" | "medium" | "hiq" | "default" = "max"
) => {
	const id = idFromYoutubeUrl(url);
	const sizes = {
		max: "maxresdefault.jpg",
		standard: "sddefault.jpg",
		medium: "mqdefault.jpg",
		hiq: "hqdefault.jpg",
		default: "default.jpg",
	};
	return `https://img.youtube.com/vi/${id}/${sizes[size]}`;
};

export const vimeoThumbnailFromUrl = async (url: string) => {
	const response = await fetch(`https://vimeo.com/api/oembed.json?url=${url}`);
	const responseJson = await response.json();
	return responseJson.thumbnail_url;
};

export const getVideoSrc = (href: string | undefined, autoplay?: boolean) => {
	let source;
	if (href) {
		const isYoutubeVideo =
			href?.includes("youtube") || href?.includes("youtu.be");
		const isVimeoVideo = href?.includes("player.vimeo.com/external");
		if (isYoutubeVideo && href) {
			source = `//www.youtube-nocookie.com/embed/${idFromYoutubeUrl(href)}`;
			if (autoplay) {
				source += "?autoplay=1&controls=0&mute=1";
			} else {
				source += "?autoplay=0&controls=1&mute=0";
			}
		}
		if (isVimeoVideo && href) {
			source = `https://player.vimeo.com/video/${idFromVimeoUrl(
				href
			)}??autoplay=1&controls=0&mute=1`;
		}
	}
	return source;
};

// TEMPORTAL API KEY FROM CARLOS TORRES' SECUOYAS ACCOUNT
// THIS API KEY IS ONLY FOR DEVELOPMENT
// EVERY INSTANCE SHOULD HAVE ITS OWN API KEY

const developmentApiKey = "AIzaSyBBrr73hkvButtHfvXwpfGJrMIbQ8ynUCU";

const getPlaylistId = (url: string) => {
	const regExp =
		/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:playlist\?list=)?(.+)$/;
	const match = url?.match(regExp);
	if (match && match[1]) {
		return match[1];
	}
	return null;
};

export const getYouTubePlaylist = async (url: string) => {
	const id = getPlaylistId(url);
	const response = await fetch(
		`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2C%20contentDetails%2C%20status&playlistId=${id}&key=${developmentApiKey}&
		maxResults=10`
	);
	const responseJson = await response.json();
	return responseJson.items;
};
