import { AnchorTabProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<AnchorTabProps> = {
	schemaType: "component",
	component: "AnchorTab",
	displayName: "Anchor Tab",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ImageField",
					title: "Icon",
					key: "icon",
					hideable: true,
				},
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					placeholder: "Type a title",
					isMockup: true,
				},

				{
					type: "UrlField",
					title: "Anchor",
					key: "anchor",
					placeholder: "Select a page",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "AnchorTab",
		title: "Lorem Ipsum",
		anchor: undefined,
		icon: null,
	},
};

export default schema;
