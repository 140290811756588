import { Schema } from "@griddo/core";

export const NEWS: Schema.PageContentType = {
	dataPacks: ["NEWS_PACK"],
	title: "News",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["NewsDetail"],
		fields: [
			// Title is mandatory and must be in the first place of `fields`
			{
				type: "TextField",
				key: "title",
				title: "Title",
				from: "title",
			},

			{
				type: "DateField",
				key: "newsDate",
				title: "Date",
				from: "date",
				indexable: true,
			},

			{
				type: "ImageField",
				title: "Image",
				key: "image",
				from: "image",
			},

			{
				type: "RichText",
				title: "Abstract",
				key: "abstract",
				from: "abstract",
			},

			{
				type: "RichText",
				title: "Content",
				key: "content",
				from: "content",
			},

			{
				type: "AsyncCheckGroup",
				title: "News Category",
				key: "newsCategory",
				from: "newsCategory",
				source: "NEWS_CATEGORY",
			},
		],
	},
};
