import { Schema } from "@griddo/core";

// Lets TypeScript do them type inferring for the `themes` to extract theme
// values as union type. Otherwise is kind of impossible due to the explicit
// Type `Schema.Themes`

const themes = [
	{
		default: true,
		label: "CUNEF theme",
		value: "cunef-theme",
	},
	{
		default: true,
		label: "Secondary theme",
		value: "second-theme",
	},
	{
		default: true,
		label: "Business theme",
		value: "business-theme",
	},
	{
		default: true,
		label: "Law theme",
		value: "law-theme",
	},
	{
		default: true,
		label: "Stem theme",
		value: "stem-theme",
	},
] as const;

const schema: Readonly<Schema.Themes> = themes;

export { themes };
export default schema;
