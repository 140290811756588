import { LocationComponentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { richtext } from "@schemas/presets";
const schema: Schema.Component<LocationComponentProps> = {
	schemaType: "component",
	component: "LocationComponent",
	displayName: "LocationComponent",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "title",
					title: "Title",
					placeholder: "Location title",
					isMockup: true,
					mandatory: true,
					humanReadable: true,
				},
				{ ...richtext, key: "content", title: "Content" },
				{
					type: "NumberField",
					key: "latitude",
					title: "Latitude",
					mandatory: true,
					helptext: "Example: 40.43010660646391",
				},
				{
					type: "NumberField",
					key: "longitude",
					title: "Longitude",
					mandatory: true,
					helptext: "Example: -3.711409670669836",
				},
				{
					title: "Email",
					type: "TextField",
					key: "email",
					placeholder: "example@example.com",
					isMockup: true,
					hideable: true,
				},
				{
					title: "Phone number",
					type: "TextField",
					key: "phone",
					placeholder: "+34 123 456 789",
					isMockup: true,
					hideable: true,
				},
				{
					type: "UrlField",
					title: "External link",
					key: "url",
					advanced: true,
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "LocationComponent",
		title: "Location title",
		url: {
			newTab: true,
		},
	},
};

export default schema;
