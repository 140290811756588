// Gatsby SSR API
const normaliceCSS = (
	<link
		rel="stylesheet"
		href="/normalize.css"
		as="style"
		id="griddo-normalize.css"
	/>
);

const griddoWebfonts = (
	<link rel="stylesheet" href="/webfonts.css" as="style" id="griddo-webfonts" />
);

//const griddoWebfonts = (
//	<link
//		rel="preload"
//		href="/webfonts.css"
//		as="style"
//		// This id is required in order to usen it later in the `builder.browser.js`
//		id="griddo-webfonts"
//		crossOrigin
//		// This onload only works on Griddo Editor (AX)
//		// The code to trigger the onload for the build phase (CX) is th builder.browser.js
//		onLoad="this.onload=null;this.rel='stylesheet'"
//	/>
//);

const cookieDelayShowBannerMobile = (
	<script
		dangerouslySetInnerHTML={{
			__html: `function showBannerCP(){document.getElementById("onetrust-banner-sdk").style.display="flex",window.removeEventListener("touchstart",showBannerCP),window.removeEventListener("scroll",showBannerCP),window.removeEventListener("click",showBannerCP),window.removeEventListener("mousemove",showBannerCP)}window.innerWidth<=700&&document.addEventListener("DOMNodeInserted",(function(e){"onetrust-banner-sdk"==e.target.id&&(document.getElementById("onetrust-banner-sdk").style.display="none",window.addEventListener("touchstart",showBannerCP),window.addEventListener("scroll",showBannerCP),window.addEventListener("click",showBannerCP),window.addEventListener("mousemove",showBannerCP),setTimeout((function(){showBannerCP()}),5e3))}),!1);`,
		}}
	/>
);

// En AX siempre cargamos todas las calses de Grisso para tener acceso a todas
// las clases de Grisso.
const grissoDevMode = (
	<link rel="stylesheet" href="/grisso.css" as="style" id="grisso" />
);

const preconnectGTM = (
	<link rel="preconnect" href="//www.googletagmanager.com" />
);

const prefetchGTM = (
	<link rel="dns-prefetch" href="https://www.googletagmanager.com/" />
);

const preconnectDAM = (
	<link rel="preconnect" href="https://images.griddo.cunef.edu/" />
);

const prefetchDAM = (
	<link rel="dns-prefetch" href="https://images.griddo.cunef.edu/" />
);

const preconnectCookie = (
	<link rel="dns-prefetch" href="https://cdn.cookielaw.org" />
);

const prefetchCookie = (
	<link rel="preconnect" href="https://cdn.cookielaw.org" />
);

const prefetchAssets = (
	<link rel="dns-prefetch" href="https://assets.griddo.cunef.edu/" />
);
const preconnectAssets = (
	<link rel="preconnect" href="https://assets.griddo.cunef.edu/" />
);

const prefetchAssetsPRE = (
	<link rel="dns-prefetch" href="https://assets.pre.griddo.cunef.edu/" />
);
const preconnectAssetsPRE = (
	<link rel="preconnect" href="https://assets.pre.griddo.cunef.edu/" />
);

const prefetchAssetsDEV = (
	<link rel="dns-prefetch" href="https://assets.dev.griddo.cunef.edu/" />
);
const preconnectAssetsDEV = (
	<link rel="preconnect" href="https://assets.dev.griddo.cunef.edu/" />
);

const instancesToInclude = {
	CUNEF: [preconnectAssets, prefetchAssets],
	"CUNEF-pro": [preconnectAssets, prefetchAssets],
	"CUNEF-pre": [preconnectAssetsPRE, prefetchAssetsPRE],
	"CUNEF-dev": [preconnectAssetsDEV, prefetchAssetsDEV],
};

function getPreconnectAndPrefetchAssets() {
	// eslint-disable-next-line no-prototype-builtins
	if (instancesToInclude.hasOwnProperty(process.env.CLIENT)) {
		return instancesToInclude[process.env.CLIENT];
	}
}

// onRenderBody hook
function onRenderBody({ setHeadComponents, setBodyAttributes, pathname }) {
	const commonHeadComponents = [];

	// Set different scripts for Griddo Builder and Griddo Editor.
	const headComponents =
		pathname === "ax-editor"
			? [...commonHeadComponents, grissoDevMode, normaliceCSS]
			: [...commonHeadComponents];

	setHeadComponents(headComponents);
	setBodyAttributes({ id: "griddo-site" });
}

function onPreRenderHTML({
	getHeadComponents,
	replaceHeadComponents,
	getPostBodyComponents,
	replacePostBodyComponents,
}) {
	// eslint-disable-next-line no-prototype-builtins
	if (instancesToInclude.hasOwnProperty(process.env.CLIENT)) {
		const postBodyComponents = getPostBodyComponents();
		const headComponents = getHeadComponents();

		const allComponents = [
			normaliceCSS,
			griddoWebfonts,
			preconnectGTM,
			prefetchGTM,
			prefetchDAM,
			preconnectDAM,
			preconnectCookie,
			prefetchCookie,
			...getPreconnectAndPrefetchAssets(),
			cookieDelayShowBannerMobile,
			...postBodyComponents,
			headComponents,
		];

		replaceHeadComponents(allComponents);
		replacePostBodyComponents([]);
	}
}

export default {
	onRenderBody,
	onPreRenderHTML,
};
