import { BasicIntroFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<BasicIntroFormProps> = {
	schemaType: "component",
	component: "BasicIntroForm",
	displayName: "Basic Intro Form",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ReferenceField",
					title: "Programs",
					sources: [{ structuredData: "PROGRAM" }],
					selectionType: ["auto", "manual"],
					key: "data",
				},
				{
					type: "ComponentContainer",
					title: "Intro Form",
					key: "form",
					whiteList: ["BasicForm"],
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "BasicIntroForm",
		form: {
			component: "BasicForm",
			title: {
				content: "Lorem ipsum",
				tag: "h3",
			},
			content: "Lorem ipsum paragraph",
			buttonLabel: "Enviar",
		},
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "alpha",
			quantity: 0,
			sources: [{ structuredData: "PROGRAM" }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/IntroForm/thumbnail@1x.png",
		"2x": "/thumbnails/modules/IntroForm/thumbnail@2x.png",
	},
};

export default schema;
