import { BasicFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, richtext } from "@schemas/presets";

const schema: Schema.Component<BasicFormProps> = {
	schemaType: "component",
	component: "BasicForm",
	displayName: "Form",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					mandatory: false,
				},
				{
					...richtext,
					title: "Content",
					key: "content",
				},
				{
					title: "Button label",
					key: "buttonLabel",
					type: "TextField",
					placeholder: "Type a title",
				},
			],
		},
	],

	default: {
		component: "BasicForm",
		title: {
			content: "Lorem ipsum",
			tag: "h3",
		},
		content: "Lorem ipsum paragraph",
		buttonLabel: "Enviar",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/IntroForm/thumbnail@1x.png",
		"2x": "/thumbnails/modules/IntroForm/thumbnail@2x.png",
	},
};

export default schema;
