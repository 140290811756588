import * as React from "react";

export type EntryState = Record<string, Array<string>>;

/**
 * Returns an object with the structure { key: [] }. Being the key the name of the data-useForm and the array the values from the forms.
 */
export const useSimpleFormValues = (props: {
	/** A reference to a form to get input elements values with the data attribute. */
	formRef: React.MutableRefObject<HTMLFormElement | null>;
	/** The name of the `data-*` attribute to filter the selects. Default `useForm`. */
	dataAttributeName?: string;
	/** If true the hook remove keys with empty arrays. Default `true` */
	isSanitized?: boolean;
}): [EntryState | undefined, () => void] => {
	const { formRef, dataAttributeName = "useForm", isSanitized = true } = props;

	// Selection ids state.
	const [formValues, setFormValues] = React.useState<EntryState>();

	React.useEffect(() => {
		updateFormValues();
	}, [formRef.current]);

	function updateFormValues(): void {
		if (formRef.current === null) {
			return;
		}

		const formData = new FormData(formRef.current);

		const forms = Array.from(formData).map((formElement) => {
			const [name, value] = formElement;
			const element = formRef.current?.elements.namedItem(name);

			const dataAttributeValue =
				(element instanceof HTMLElement &&
					element?.dataset[dataAttributeName.toLowerCase()]) ||
				(element instanceof RadioNodeList &&
					element[0] instanceof HTMLFieldSetElement &&
					element[0].dataset[dataAttributeName.toLowerCase()]) ||
				"";

			// `value` has the `FormDataEntryValue` type wich is = File | string
			// In this case we cast as string as long `<input type='file' />` is not
			// supported by this hook.
			return {
				value: value as string,
				dataAttributeValue,
			};
		});

		const formEntries = forms.reduce((accumulator, current) => {
			const { value, dataAttributeValue } = current;
			accumulator[dataAttributeValue] = accumulator[dataAttributeValue] || [];
			if (value) {
				accumulator[dataAttributeValue].push(value);
			}

			return accumulator;
		}, {} as EntryState);

		// Remove keys with empty arrays
		const formEntriesSanitized: EntryState = {};
		Object.keys(formEntries).forEach((key) => {
			if (formEntries[key].length > 0) {
				formEntriesSanitized[key] = formEntries[key];
			}
		});

		// Set the state with forms input values
		setFormValues(isSanitized ? formEntriesSanitized : formEntries);
	}

	return [
		/** An object with the structure { key: [] } with the values from the form */
		formValues,
		/** A function to update the formValues state */
		updateFormValues,
	];
};
