import { HeroQuickfactComponentProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<HeroQuickfactComponentProps> = {
	schemaType: "component",
	component: "HeroQuickfactComponent",
	displayName: "HeroQuickfactComponent",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					placeholder: "Quickfact title",
				},
				{
					title: "QUICK_FACTS",
					type: "ReferenceField",
					key: "data",
					mandatory: true,
					sources: [{ structuredData: "QUICK_FACTS" }],
					selectionType: ["manual"],
					maxItems: 1,
				},
			],
		},
	],

	default: {
		component: "HeroQuickfactComponent",
		title: "Lorem ipsum",
		description: "Lorem ipsum",
		hasDistributorData: true,
		data: {
			sources: [{ structuredData: "QUICK_FACTS" }],
			mode: "manual",
			order: "alpha",
			quantity: 0,
		},
	},
};

export default schema;
