import { AccordionCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	detail,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

import AccordionComponent from "../components/AccordionComponent";

const schema: Schema.Module<AccordionCollectionProps> = {
	schemaType: "module",
	component: "AccordionCollection",
	category: "collection",
	displayName: "AccordionCollection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },
				{ ...detail },
				{
					type: "ComponentArray",
					title: "Accordion Element",
					key: "AccordionComponents",
					contentType: "components",
					whiteList: ["AccordionComponent"],
				},
				{ type: "TextField", key: "firstTitle", title: "First Title" },
				{
					type: "ConditionalField",
					title: "Titles",
					key: "accordions",
					options: [
						{ name: "Title 1", value: "layout1", title: "Layout 1" },
						{ name: "Title 2", value: "layout2", title: "Layout 2" },
					],
					fields: [
						{
							type: "TextField",
							key: "secondTitle",
							title: "Second Title",
							condition: "layout2",
						},
						{
							type: "ComponentArray",
							title: "Accordion Element 2",
							key: "SecondAccordionComponent",
							contentType: "components",
							whiteList: ["AccordionComponent"],
							condition: "layout2",
						},
					],
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "AccordionCollection",
		subtheme: "default",
		accordions: "layout1",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		AccordionComponents: [
			{ ...AccordionComponent.default },
			{ ...AccordionComponent.default },
			{ ...AccordionComponent.default },
		],
		SecondAccordionComponent: [{ ...AccordionComponent.default }],
		firstTitle: "",
		secondTitle: "",
		detail: "Lorem ipsum paragraph",
		link: {
			...defaultLink,
			text: "Saber más",
			variant: "tertiary",
		},
		anchorID: null,
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/AccordionCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/AccordionCollection/thumbnail@2x.png",
	},
};

export default schema;
