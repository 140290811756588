import { ProgramDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { DegreeType } from "@schemas/presets/degreeType";
import { richtext } from "@schemas/presets/richtext";

import AccordionCollection from "../modules/AccordionCollection";
import BasicContent from "../modules/BasicContent";
import BasicText from "../modules/BasicText";
import FeaturedContent from "../modules/FeaturedContent";
import HeroProgram from "../modules/HeroProgram";
import ProgramQuickFacts from "../modules/ProgramQuickFacts";

const schema: Schema.Template<ProgramDetailProps> = {
	schemaType: "template",
	displayName: "Program Detail",
	component: "ProgramDetail",
	dataPacks: ["PROGRAMS_PACK"],
	type: { label: "Static", value: "static", mode: "detail" },

	config: [
		{
			type: "Select",
			key: "pageTranslationLanguage",
			title: "Custom Page translation language",
			helptext:
				"Override default page static translations with the selected language",
			options: [
				{ label: "English", value: "en_GB" },
				{ label: "Spanish", value: "es_ES" },
			],
		},
	],

	content: [
		{ ...richtext, title: "Study intro", key: "intro" },

		{
			type: "AsyncCheckGroup",
			key: "areas",
			source: "AREAS",
			title: "Areas",
			mandatory: true,
		},

		{
			type: "ImageField",
			key: "image",
			title: "image",
			hideable: true,
		},

		{
			type: "TextField",
			key: "programTypeLabel",
			title: "Programme",
			hideable: true,
			placeholder: "Type a programme type",
		},

		{
			type: "TextField",
			key: "learningOption",
			title: "Delivery mode",
			hideable: true,
			placeholder: "Type a delivery mode",
		},

		{
			type: "TextField",
			key: "languageLabel",
			title: "Language",
			hideable: true,
			placeholder: "Type a language option",
		},

		{
			type: "TextField",
			key: "credits",
			title: "Credits",
			hideable: true,
			placeholder: "Type a credits",
		},

		{
			type: "TextField",
			key: "places",
			title: "Places",
			hideable: true,
			placeholder: "Type a places",
		},

		{
			type: "AsyncCheckGroup",
			key: "programType",
			source: "PROGRAM_TYPE",
			title: "Programme",
			mandatory: true,
		},
		{ ...DegreeType },
		{
			type: "AsyncCheckGroup",
			key: "language",
			source: "LANGUAGE",
			title: "Language",
			mandatory: true,
		},

		{
			type: "UniqueCheck",
			title: "New",
			key: "isNew",
			options: [{ title: "new" }],
		},

		{
			type: "TextField",
			key: "crmId",
			title: "CRM id",
			placeholder: "CRM id",
		},

		{
			type: "ImageField",
			key: "programImage",
			title: "Program Image",
			mandatory: true,
		},

		{
			type: "ComponentArray",
			title: "Hero Content",
			key: "heroSection",
			contentType: "modules",
			whiteList: ["HeroProgram"],
			maxItems: 1,
		},

		{
			type: "ComponentArray",
			title: "Navigation Content",
			key: "navigationContent",
			contentType: "modules",
			whiteList: ["ProgramNavigation"],
			maxItems: 1,
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"CypherCollection",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"FullImageWithText",
				"FullVideo",
				"HighlightQuote",
				"LocationMap",
				"LogoCollection",
				"NewsHighlightDistributor",
				"NewsSimpleDistributor",
				"ProgramsDistributor",
				"QuoteTestimonial",
				"SocialShare",
				"TabsModules",
				"Wysiwyg",
			],
			key: "mainSection",
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "ProgramDetail",
		abstract: "Lorem ipsum paragraph",
		pageTranslationLanguage: undefined,
		crmId: "",
		degreetype: undefined,

		// HERO
		heroSection: {
			component: "Section",
			name: "Section",
			modules: [{ ...HeroProgram.default }],
		},

		// NAVIGATION
		navigationContent: {
			component: "Section",
			name: "Section",
			modules: [
				{
					component: "ProgramNavigation",
					startAdmission: "https://www.cunef.edu/",
					form: {
						component: "BasicForm",
						title: {
							content: "Lorem ipsum",
							tag: "h3",
						},
						content: "Lorem ipsum paragraph",
						buttonLabel: "Descargar folleto",
					},
					programTabs: [
						{
							component: "ProgramNavigationTab",
							title: "Presentación",
							elements: [
								{ ...ProgramQuickFacts.default },
								{ ...FeaturedContent.default },
							],
						},
						{
							component: "ProgramNavigationTab",
							title: "Plan de Estudios",
							elements: [
								{ ...BasicText.default },
								{ ...AccordionCollection.default },
							],
						},
						{
							component: "ProgramNavigationTab",
							title: "Inserción Laboral",
							elements: [{ ...BasicContent.default }],
						},
					],
				},
			],
		},

		// MAIN SECTION
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProgramDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProgramDetail/thumbnail@2x.png",
	},
};

export default schema;
