import { Error404Props } from "@autoTypes";
import { Schema } from "@griddo/core";
import { defaultLink, heading, link, richtext } from "@schemas/presets";

const schema: Schema.Template<Error404Props> = {
	schemaType: "template",
	displayName: "Error 404",
	component: "Error404",
	type: { label: "Static", value: "static", special: "404" },
	singleInstance: true,

	content: [
		{ ...heading, key: "msg", title: "Message" },
		{
			type: "TextField",
			key: "subtitle",
			title: "Subtitle",
			placeholder: "Type a subtitle",
			mandatory: true,
		},
		{ ...richtext, title: "Content", key: "content" },
		{
			...link,
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: ["BasicContent"],
			key: "relatedContent",
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "Error404",
		msg: { content: "Error 404", tag: "h2" },
		subtitle: "Página no encontrada",
		content: `Parece que la página a la que intenta acceder no existe.
		¿Desea seguir buscando?`,
		link: { ...defaultLink, text: "Volver al inicio", variant: "tertiary" },
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Error404/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Error404/thumbnail@2x.png",
	},
};

export default schema;
