import { NewsDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<NewsDetailProps> = {
	schemaType: "template",
	displayName: "News Detail",
	component: "NewsDetail",
	dataPacks: ["NEWS_PACK"],
	type: { label: "Static", value: "static", mode: "detail" },

	content: [
		{
			title: "Image",
			type: "ImageField",
			key: "image",
			hideable: true,
		},

		{
			type: "DateField",
			key: "date",
			title: "Date",
		},

		{
			type: "RichText",
			placeholder: "Type an abstract",
			isMockup: true,
			humanReadable: true,
			title: "Abstract",
			key: "abstract",
		},

		{
			type: "Wysiwyg",
			placeholder: "Type a news detail",
			isMockup: true,
			humanReadable: true,
			mandatory: true,
			title: "Content",
			key: "content",
		},

		{
			title: "News Category",
			type: "AsyncCheckGroup",
			key: "newsCategory",
			source: "NEWS_CATEGORY",
		},

		{
			type: "ReferenceField",
			key: "data",
			sources: [{ structuredData: "NEWS" }],
			title: "Related news",
		},

		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			contentType: "modules",
			whiteList: [
				"BasicContent",
				"BasicHero",
				"BasicText",
				"CardCollection",
				"CypherCollection",
				"FeaturedBlock",
				"Files",
				"HighlightQuote",
				"QuoteTestimonial",
				"SocialShare",
			],
		},
	],

	default: {
		type: "template",
		templateType: "NewsDetail",
		content: "Lorem ipsum paragraph",
		date: "date string",
		hasDistributorData: true,
		deparment: [],
		image: null,
		abstract: "Lorem ipsum paragraph",
		data: {
			mode: "auto",
			order: "alpha",
			quantity: 2,
			sources: [
				{
					structuredData: "NEWS",
				},
			],
			/* ---------- */
			/* opcionales */
			/* ---------- */
			// order: 'alpha-ASC',
			// filter: ['string'],
			// fullRelations: true,
			// quantity: 2
		},

		relatedContent: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "SocialShare",
					socialMedia: ["facebook", "linkedin", "twitter"],
					verticalSpacing: "small",
					subtheme: "inverse",
				},
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/NewsDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/NewsDetail/thumbnail@2x.png",
	},
};

export default schema;
