import { OrderedCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<OrderedCardProps> = {
	schemaType: "component",
	component: "OrderedCard",
	displayName: "OrderedCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },
				{
					...richtext,
					title: "Detail",
					key: "detail",
				},
			],
		},
	],

	default: {
		component: "OrderedCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		detail: "Detail",
	},

	thumbnails: {
		"1x": "/thumbnails/components/OrderedCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/OrderedCard/thumbnail@2x.png",
	},
};

export default schema;
