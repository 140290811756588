import { UIFields } from "@griddo/core";

export const DegreeType: UIFields.RadioGroup = {
	type: "RadioGroup",
	key: "degree_type",
	title: "Degree type",
	options: [
		{ value: "OFFICIAL", title: "Official", name: "official" },
		{ value: "CUSTOM", title: "Custom", name: "custom" },
	],
};
