import { HeroSlideProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";
import { button } from "@schemas/presets/button";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<HeroSlideProps> = {
	schemaType: "component",
	component: "HeroSlide",
	displayName: "HeroSlide",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					key: "media",
					title: "Media",
					whiteList: ["Image", "VideoComponent"],
				},
				{ ...heading },
				{
					...richtext,
					title: "Subtitle",
					key: "subtitle",
				},
				{
					...richtext,
					title: "Intro",
					key: "intro",
				},
				{ ...button },
			],
		},
	],

	default: {
		component: "HeroSlide",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: "Subtitle",
		intro: "Intro",
		image: { component: "Image" },
		link: { component: "Button" },
		media: {
			image: {
				component: "Image",
			},
			videoComponent: {
				component: "VideoComponent",
			},
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/HeroSlide/thumbnail@1x.png",
		"2x": "/thumbnails/components/HeroSlide/thumbnail@2x.png",
	},
};

export default schema;
