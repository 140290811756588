import { LegalTemplateProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<LegalTemplateProps> = {
	schemaType: "template",
	displayName: "LegalTemplate",
	component: "LegalTemplate",
	type: { label: "Static", value: "static" },

	dimensions: [],

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			whiteList: ["BasicHero"],
			key: "heroSection",
			contentType: "modules",
			maxItems: 1,
		},
		{
			title: "Note",
			key: "noteGeneral",
			type: "NoteField",
			value: {
				title: "Table of cookies",
				text: "In legal section, add this code [cookies-table] on a single line where you want to place the cookies declaration table. It will only be displayed on the final site.",
			},
		},
		{
			type: "ComponentArray",
			title: "Legal Section",
			whiteList: ["Wysiwyg"],
			key: "legalSection",
			contentType: "modules",
			maxItems: 1,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: ["AccordionCollection"],
			key: "relatedContent",
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "LegalTemplate",

		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},

		legalSection: {
			component: "Section",
			name: "Legal Section",
			modules: [],
		},

		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/LegalTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/LegalTemplate/thumbnail@2x.png",
	},
};

export default schema;
