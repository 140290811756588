import { UIFields } from "@griddo/core";

export const detail: UIFields.RichText = {
	type: "RichText",
	title: "Detail",
	key: "detail",
	placeholder: "Type a detail",
	hideable: true,
	isMockup: true,
};
