import { usePage } from "@griddo/core";
import { IconNames } from "@ui/elements/Icon";

type AllowedSocialIconNames = Extract<
	IconNames,
	"facebook" | "twitter" | "linkedin"
>;

export const SOCIAL_SHARE_LINKS = {
	facebook: "https://www.facebook.com/sharer.php?u=",
	twitter: "https://twitter.com/intent/tweet?source=webclient&text=",
	linkedin: "https://www.linkedin.com/sharing/share-offsite/?url=",
	// whatsapp: "whatsapp://send?text=",
};

/**
 * @todo: JSDoc
 */
export function useSocialShareLinks() {
	const { title: pageTitle, fullUrl } = usePage();

	const shareLinks: Record<
		AllowedSocialIconNames,
		{
			icon: AllowedSocialIconNames;
			label: string;
			shareLink: string;
		}
	> = {
		facebook: {
			icon: "facebook",
			label: "Facebook",
			shareLink: `${SOCIAL_SHARE_LINKS.facebook}${fullUrl}&t=${pageTitle}`,
		},
		twitter: {
			icon: "twitter",
			label: "Twitter",
			shareLink: `${SOCIAL_SHARE_LINKS.twitter}${pageTitle} ${fullUrl}`,
		},
		linkedin: {
			icon: "linkedin",
			label: "LinkedIn",
			shareLink: `${SOCIAL_SHARE_LINKS.linkedin}${fullUrl}&tile=${pageTitle} ${fullUrl}`,
		},
		// whatsapp: {
		// 	label: "whatsapp",
		// 	icon: "whatsapp",
		// 	shareLink: `${socialShareLinks.whatsapp}${pageTitle} ${fullUrl}`,
		// },
	};

	return shareLinks;
}
