import { CardCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	detail,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<CardCollectionProps> = {
	schemaType: "module",
	component: "CardCollection",
	category: "collection",
	displayName: "Card Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					hideable: true,
					mandatory: false,
				},

				{
					...detail,
					hideable: true,
					mandatory: false,
				},
				{
					type: "ComponentArray",
					elementUniqueSelection: true,
					title: "Cards",
					key: "elements",
					whiteList: ["BasicCard", "ImageCard"],
					contentType: "components",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/CardCollection/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/CardCollection/Layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/CardCollection/Layouts/layout3.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
				{
					type: "VisualUniqueSelection",
					title: "Card Style",
					key: "kind",
					elementUniqueSelection: true,
					reference: "elements",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "BasicCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/basicCard.png",
						},
						{
							value: "ImageCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/imageCard.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "CardCollection",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		kind: "BasicCard",
		elements: [
			{
				component: "BasicCard",
			},
			{
				component: "BasicCard",
			},
			{
				component: "BasicCard",
			},
		],
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "tertiary",
		},
		anchorID: null,
		verticalSpacing: "medium",
		layout: "layout2",
		subtheme: "default",
		cardStyle: "imageCard",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CardCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CardCollection/thumbnail@2x.png",
	},
};

export default schema;
