import AccordionCollection from "./AccordionCollection";
import BasicContent from "./BasicContent";
import BasicHero from "./BasicHero";
import BasicText from "./BasicText";
import CardCollection from "./CardCollection";
import ChartCollection from "./ChartCollection";
import CypherCollection from "./CypherCollection";
import FeaturedAudiovisual from "./FeaturedAudiovisual";
import FeaturedBlock from "./FeaturedBlock";
import FeaturedContent from "./FeaturedContent";
import Files from "./Files";
import Footer from "./Footer";
import FullImageWithText from "./FullImageWithText";
import FullVideo from "./FullVideo";
import Header from "./Header";
import HeroCarousel from "./HeroCarousel";
import HeroLanding from "./HeroLanding";
import HeroProgram from "./HeroProgram";
import HighlightQuote from "./HighlightQuote";
import InnerBasicContent from "./InnerBasicContent";
import InnerBasicText from "./InnerBasicText";
import InnerModalLinkCollection from "./InnerModalLinkCollection";
import InnerWysiwyg from "./InnerWysiwyg";
import IntroForm from "./IntroForm";
import LandingIntro from "./LandingIntro";
import LandingProgramNavigation from "./LandingProgramNavigation";
import LocationMap from "./LocationMap";
import LogoCollection from "./LogoCollection";
import ModalLinkCollection from "./ModalLinkCollection";
import NewsHighlightDistributor from "./NewsHighlightDistributor";
import NewsSimpleDistributor from "./NewsSimpleDistributor";
import OdsCollection from "./OdsCollection";
import OrderedCollection from "./OrderedCollection";
import PeopleDistributor from "./PeopleDistributor";
import ProgramListTabs from "./ProgramListTabs";
import ProgramNavigation from "./ProgramNavigation";
import ProgramQuickFacts from "./ProgramQuickFacts";
import ProgramsDistributor from "./ProgramsDistributor";
import QuoteTestimonialCollection from "./QuoteTestimonialCollection";
import SocialShare from "./SocialShare";
import TabsModules from "./TabsModules";
import Wysiwyg from "./Wysiwyg";

export default {
	AccordionCollection,
	BasicContent,
	BasicHero,
	BasicText,
	CardCollection,
	ChartCollection,
	CypherCollection,
	FeaturedAudiovisual,
	FeaturedBlock,
	FeaturedContent,
	Files,
	Footer,
	FullImageWithText,
	FullVideo,
	Header,
	HeroCarousel,
	HeroLanding,
	HeroProgram,
	HighlightQuote,
	InnerBasicContent,
	InnerBasicText,
	InnerModalLinkCollection,
	InnerWysiwyg,
	IntroForm,
	LandingIntro,
	LandingProgramNavigation,
	LocationMap,
	LogoCollection,
	ModalLinkCollection,
	NewsHighlightDistributor,
	NewsSimpleDistributor,
	OdsCollection,
	OrderedCollection,
	QuoteTestimonialCollection,
	PeopleDistributor,
	ProgramListTabs,
	ProgramNavigation,
	ProgramQuickFacts,
	ProgramsDistributor,
	SocialShare,
	TabsModules,
	Wysiwyg,
};
