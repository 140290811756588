import { TabsModulesProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	detail,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<TabsModulesProps> = {
	schemaType: "module",
	component: "TabsModules",
	category: "interactive",
	displayName: "Tabs Modules",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					hideable: true,
					mandatory: false,
				},
				{
					...detail,
					hideable: true,
				},
				{
					type: "ComponentArray",
					title: "Tabs",
					key: "elements",
					whiteList: ["TabsComponent"],
					contentType: "components",
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "TabsModules",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		elements: [
			{
				component: "TabsComponent",
				title: { content: "Tab 1", tag: "h2" },
				content: {
					innerBasicText: {
						component: "InnerBasicText",
					},
					innerWysiwyg: {
						component: "InnerWysiwyg",
					},
				},
			},
			{
				component: "TabsComponent",
				title: { content: "Tab 2", tag: "h2" },
				content: {
					innerBasicText: {
						component: "InnerBasicText",
					},
					innerWysiwyg: {
						component: "InnerWysiwyg",
					},
				},
			},
			{
				component: "TabsComponent",
				title: { content: "Tab 3", tag: "h2" },
				content: {
					innerBasicText: {
						component: "InnerBasicText",
					},
					innerWysiwyg: {
						component: "InnerWysiwyg",
					},
				},
			},
		],
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "tertiary",
		},
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Tabs/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Tabs/thumbnail@2x.png",
	},
};

export default schema;
