import { Core } from "@griddo/core";

export const mapAnyContenType = <T extends Core.ListContentType<T>>(
	item: T
) => ({
	content: item,
	relatedPage: {
		pageId: item.id,
		url: item.url,
	},
});
