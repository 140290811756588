import { ProgramQuickFactsProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Module<ProgramQuickFactsProps> = {
	schemaType: "module",
	component: "ProgramQuickFacts",
	category: "content",
	displayName: "ProgramQuickFacts",

	configTabs: [],

	default: {
		component: "ProgramQuickFacts",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramQuickFacts/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramQuickFacts/thumbnail@2x.png",
	},
};

export default schema;
