import { ButtonProps, ImageProps } from "@autoTypes";
import { Core, Fields, Hideable } from "@griddo/core";

/**
 * Class to check some props from API (AX/CX) in order to do conditional renders
 *
 * @example
 * { Has.heading(title) && <Heading tag={title.tag}>{title.content}</Heading> }
 */
export class Has {
	/**
	 * Check if a breadcrumb has items
	 */
	static breadcrumb(bc?: Core.Breadcrumb): bc is Core.Breadcrumb {
		if (!bc) {
			return false;
		}
		return bc?.length > 0;
	}

	/**
	 * Check if a link has text content.
	 */
	static linkText(link?: Hideable<ButtonProps>): link is ButtonProps {
		return !!link?.text;
	}

	/**
	 * Check if a link has a valid link URL.
	 */
	static link(linkField?: Hideable<ButtonProps>): linkField is ButtonProps {
		return !!(
			linkField?.url?.href ||
			// @ts-expect-error linkTo se resuelve a linkToURL después de
			// guardar la página, lo dejamos para evitar cosas raras en AX pero
			// no es algo que vaya a venir en CX.
			linkField?.url?.linkTo ||
			linkField?.url?.linkToURL
		);
	}

	/**
	 * Check if a heading has both content and a tag.
	 */
	static heading(prop?: Hideable<Fields.Heading>): prop is Fields.Heading {
		return !!(prop?.content && prop?.tag);
	}

	/**
	 * Check if an image has a valid image URL.
	 */
	static image(image?: ImageProps): image is ImageProps {
		return !!image?.imageField?.url;
	}
}
