import { ProgramsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	detail,
	heading,
	themeSelector,
	link,
} from "@schemas/presets";

const schema: Schema.ContentTypeModule<ProgramsDistributorProps> = {
	schemaType: "module",
	component: "ProgramsDistributor",
	category: "studies",
	displayName: "Program Distributor",
	dataPacks: ["PROGRAMS_PACK"],

	configTabs: [
		{
			title: "content",
			fields: [
				// BASIC INTRO
				{ ...heading, mandatory: false },
				{ ...detail },
				{
					type: "ImageField",
					key: "defaultImage",
					title: "Default Image",
					mandatory: true,
				},
				//
				{
					type: "ReferenceField",
					title: "News",
					key: "data",
					sources: [{ structuredData: "PROGRAM" }],
					selectionType: ["auto", "manual"],
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }],
		},
	],

	default: {
		component: "ProgramsDistributor",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "alpha",
			quantity: 2,
			sources: [
				{
					structuredData: "PROGRAM",
				},
			],
			/* ---------- */
			/* opcionales */
			/* ---------- */
			// order: 'alpha-ASC',
			// filter: ['string'],
			// fullRelations: true,
			// quantity: 2
		},
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "tertiary",
		},
		anchorID: null,
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramsDistributor/thumbnail@2x.png",
	},
};

export default schema;
