import { Core } from "@griddo/core";
import * as React from "react";

interface PageLocaleContextProps {
	language?: Core.Locale;
}

interface PageLocaleProviderProps {
	children: JSX.Element;
	language?: Core.Locale;
}

const PageLocaleContext = React.createContext<PageLocaleContextProps>({
	language: undefined,
});

function PageLocaleProvider(props: PageLocaleProviderProps): JSX.Element {
	const { children, language } = props;

	return (
		<PageLocaleContext.Provider
			value={{
				language,
			}}
		>
			{children}
		</PageLocaleContext.Provider>
	);
}

export { PageLocaleContext, PageLocaleProvider };
