import { InnerBasicTextProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	themeSelector,
	verticalSpacing,
	wysiwyg,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<InnerBasicTextProps> = {
	schemaType: "module",
	component: "InnerBasicText",
	category: "content",
	displayName: "Inner Basic Text",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, title: "Title", key: "title", mandatory: false },
				{ title: "Subtitle", key: "subtitle", ...richtext },
				{ title: "Content", key: "content", ...wysiwyg },
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "InnerBasicText",
		title: { content: "Lorem Ipsum", tag: "h2" },
		subtitle: "Lorem ipsum paragraph",
		content: "Lorem ipsum paragraph",
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/InnerBasicText/thumbnail@1x.png",
		"2x": "/thumbnails/modules/InnerBasicText/thumbnail@2x.png",
	},
};

export default schema;
