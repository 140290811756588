import { Schema } from "@griddo/core";

const schema: Schema.MenuItem = {
	link: {
		fields: [
			{
				type: "TextField",
				title: "Tag",
				key: "tag",
				placeholder: "Nuevo",
			},
			{
				type: "ToggleField",
				title: "",
				key: "linkViewAll",
				auxText: "Check for links that are of type view all.",
				background: true,
			},
		],
	},

	group: {
		fields: [
			{
				type: "ToggleField",
				title: "Complex menu",
				key: "complexMenu",
				auxText: "Check it if the group has a complex menu behavior.",
				background: true,
			},
			{
				type: "ToggleField",
				title: "Featured group",
				key: "featuredGroup",
				auxText:
					"Check if the group has a featured menu behaviour within a complex group.",
				background: true,
			},
			{
				type: "TextArea",
				title: "Note Featured group",
				key: "noteFeaturedGroup",
				helptext: "Note for featured menus.",
				humanReadable: true,
			},
			{
				type: "ToggleField",
				title: "Headline group",
				key: "noteHeadlineGroup",
				auxText:
					"It will display headlines on the right hand side of the complex menu. Cofigured links in this group type will not work, only text will be rendered.",
				background: true,
			},
		],
	},
};

export default schema;
