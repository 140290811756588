import { GriddoAlertRegisterProps } from "@types";

const GriddoAlertRegister = async ({
	publicApiUrl,
	level = "E",
	area = "Forms",
	description,
	fullData,
	instantNotification = false,
}: GriddoAlertRegisterProps): Promise<void> => {
	const data = { level, area, description, fullData, instantNotification };

	try {
		await fetch(`${publicApiUrl}/alert`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error("Error creating Griddo alert :", error);
	}
};

export { GriddoAlertRegister };
