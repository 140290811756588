import { ProgramListTabsProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.MultiPageModule<ProgramListTabsProps> = {
	schemaType: "module",
	component: "ProgramListTabs",
	displayName: "Program list tabs",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					key: "elements",
					title: "Tabs (pages)",
					whiteList: ["ProgramListTabComponent"],
					contentType: "components",
				},
			],
		},
	],

	default: {
		component: "ProgramListTabs",
		hasGriddoMultiPage: true,
		elements: [],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramListTabs/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramListTabs/thumbnail@2x.png",
	},
};

export default schema;
