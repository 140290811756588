import { BasicTextProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	themeSelector,
	verticalSpacing,
	wysiwyg,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<BasicTextProps> = {
	schemaType: "module",
	component: "BasicText",
	category: "content",
	displayName: "Basic Text",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },
				{
					type: "FieldsDivider",
					title: "Colulmna 1",
					data: { title: "Column 1 fields", text: "" },
					key: "c1",
				},
				{ ...heading, title: "Subtitle 1", key: "subtitle1", mandatory: false },
				{ title: "Intro 1", key: "intro1", ...richtext },
				{ title: "Content1", key: "content1", ...wysiwyg },
				{
					type: "FieldsDivider",
					title: "Colulmna 2",
					data: { title: "Column 2 fields", text: "" },
					key: "c2",
				},
				{ ...heading, title: "Subtitle 2", key: "subtitle2", mandatory: false },
				{ title: "Intro 2", key: "intro2", ...richtext },
				{ title: "Content2", key: "content2", ...wysiwyg },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/BasicText/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/BasicText/Layouts/layout2.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
			],
		},
	],

	default: {
		component: "BasicText",
		title: { content: "Lorem Ipsum", tag: "h2" },
		subtitle1: { content: "Lorem Ipsum", tag: "h2" },
		intro1: "Lorem ipsum paragraph",
		content1: "Lorem ipsum paragraph",
		subtitle2: null,
		intro2: null,
		content2: null,
		anchorID: null,
		verticalSpacing: "medium",
		layout: "layout1",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicText/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicText/thumbnail@2x.png",
	},
};

export default schema;
