import { BasicContentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	heading,
	image,
	themeSelector,
	verticalSpacing,
	wysiwyg,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<BasicContentProps> = {
	schemaType: "module",
	component: "BasicContent",
	category: "content",
	displayName: "BasicContent",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...image },
				{ ...heading, mandatory: false },
				{ ...richtext, title: "Subtitle", key: "subtitle" },
				{ ...wysiwyg, title: "Content", key: "content" },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/BasicContent/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/BasicContent/Layouts/layout2.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
			],
		},
	],

	default: {
		component: "BasicContent",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		layout: "layout1",
		subtheme: "default",
		subtitle: "Lorem ipsum",
		content: "Content",
		image: { component: "Image" },
		link: {
			...defaultLink,
			text: "Saber más",
			variant: "primary",
		},
		anchorID: null,
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicContent/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicContent/thumbnail@2x.png",
	},
};

export default schema;
