import { Schema } from "@griddo/core";
import {
	defaultAltSubtheme,
	defaultSubtheme,
	link,
	themeSelector,
} from "@schemas/presets";

const theme = { ...themeSelector };
theme.options = [defaultSubtheme, defaultAltSubtheme];

const schema: Schema.Header = {
	schemaType: "module",
	displayName: "Header",
	component: "Header",
	type: "header",
	defaultNavigation: true,

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Header name",
					key: "title",
					type: "TextField",
					placeholder: "Type a title",
					mandatory: true,
				},
				{
					title: "CTA button",
					type: "ConditionalField",
					key: "typeCTABtn",
					hideable: true,
					options: [
						{
							name: "form",
							value: "form",
							title: "Form",
						},
						{
							name: "btn",
							value: "btn",
							title: "Link",
						},
					],
					fields: [
						{
							type: "ComponentContainer",
							title: "Form",
							key: "form",
							whiteList: ["BasicForm"],
							hideable: true,
							condition: "form",
						},
						{ ...link, condition: "btn", hideable: true },
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					title: "Set as Headerdefault",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Select Main menu",
					key: "menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select de site menu. It must first be created.",
				},
				{
					title: "Select Top menu",
					key: "topMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select de site menu. It must first be created.",
				},
				{
					title: "Virtual office menu",
					key: "virtualOfficeNav",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select de site menu. It must first be created.",
				},
				{
					type: "RadioGroup",
					key: "logo",
					title: "Select a logo",
					options: [
						{ title: "Logo 50 aniversario", value: "logo50", name: "logo50" },
						{ title: "Logo CUNEF", value: "logo", name: "logo" },
						{ title: "Logo CES", value: "logoCes", name: "logoCes" },
					],
				},
				theme,
			],
		},
	],

	default: {
		component: "Header",
		type: "header",
		title: "New Header",
		typeCTABtn: "form",
		form: {
			component: "BasicForm",
			title: {
				content: "Lorem ipsum",
				tag: "h3",
			},
			content: "Lorem ipsum paragraph",
			buttonLabel: "Solicitar información",
		},
		link: {
			component: "Button",
		},
		setAsDefault: false,
		menu: undefined,
		topMenu: undefined,
		virtualOfficeNav: undefined,
		subtheme: "default-alt",
		logo: "logo50",
	},
};

export default schema;
