import { TabsComponentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Component<TabsComponentProps> = {
	schemaType: "component",
	component: "TabsComponent",
	displayName: "Tabs component",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },
				{
					type: "ComponentContainer",
					title: "Content",
					key: "content",
					whiteList: ["InnerBasicText", "InnerWysiwyg"],
				},
			],
		},
	],

	default: {
		component: "TabsComponent",
		title: { content: "Lorem ipsum", tag: "h2" },
		content: {
			innerBasicText: {
				component: "InnerBasicText",
			},
			innerWysiwyg: {
				component: "InnerWysiwyg",
			},
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/Tab/thumbnail@1x.png",
		"2x": "/thumbnails/components/Tab/thumbnail@2x.png",
	},
};

export default schema;
