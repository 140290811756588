import { ProfessorDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<ProfessorDetailProps> = {
	schemaType: "template",
	displayName: "Professor Detail",
	component: "ProfessorDetail",
	dataPacks: ["PROFESSORS_PACK"],
	type: { label: "Static", value: "static", mode: "detail" },

	content: [
		{
			type: "TextField",
			title: "Name",
			key: "name",
		},

		{
			type: "TextField",
			title: "Surname",
			key: "surname",
		},

		{
			type: "TextField",
			title: "PhD",
			key: "phd",
		},

		{
			type: "TextField",
			title: "Office number",
			key: "officeNumber",
		},

		{
			type: "ImageField",
			title: "Image",
			key: "image",
		},

		{
			type: "TextField",
			title: "Email",
			key: "email",
			placeholder: "Escribe un email válido",
			validators: {
				format: "email",
			},
		},

		{
			type: "TextField",
			title: "CV",
			key: "cv",
			validators: {
				format: "URL",
			},
		},

		{
			type: "TextField",
			title: "WEBSITE",
			key: "website",
			validators: {
				format: "URL",
			},
		},

		{
			type: "TextField",
			title: "External ID",
			key: "externalID",
		},

		{
			title: "Departments",
			type: "MultiCheckSelect",
			key: "departments",
			source: "DEPARTMENTS",
			placeholder: "Departments",
		},

		{
			title: "Professor Type",
			type: "MultiCheckSelect",
			key: "professorType",
			source: "PROFESSOR_TYPE",
			placeholder: "Professor type",
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			key: "mainSection",
			contentType: "modules",
			whiteList: ["SocialShare", "TabsModules"],
		},

		{
			type: "ComponentArray",
			title: "Complementary Section",
			key: "complementarySection",
			contentType: "modules",
			// El whitelist está vacío porque PeopleDistributor, que es lo que
			// va dentro de este complementary section está indicado en los
			// `SectionList` del schema de `PeopleDistributor`:
			// 	sectionList: {
			//	  BasicTemplate: ["mainSection"],
			//	  ProfessorDetail: ["complementarySection"],
			// 	},
			whiteList: [],
		},
	],

	default: {
		type: "template",
		templateType: "ProfessorDetail",

		name: "Name",
		surname: "Surname",
		phd: "PhD text",
		officeNumber: "Office number",
		image: undefined,
		email: "email@cunef.edu",
		cv: "https://www.cunef.edu/",
		website: "",
		externalID: "external-id",

		departments: [],
		professorType: [],

		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "TabsModules",
					detail: null,
					title: null,
					link: null,
					verticalSpacing: "small",
					elements: [
						{
							component: "TabsComponent",
							title: { content: "Content tab", tag: "h2" },
							content: {
								innerBasicText: {
									component: "InnerBasicText",
									title: { content: "title", tag: "h2" },
									subtitle: "Subtitle",
									content: "lorem ipsum paragraph",
								},
								innerWysiwyg: {
									component: "InnerWysiwyg",
								},
							},
						},
					],
				},
				{
					component: "SocialShare",
					socialMedia: ["facebook", "twitter", "linkedin"],
					verticalSpacing: "small",
					subtheme: "default-alt",
				},
			],
		},

		complementarySection: {
			component: "Section",
			name: "Complementary Section",
			modules: [
				{
					component: "PeopleDistributor",
					data: {
						mode: "auto",
						order: "alpha",
						quantity: 3,
						sources: [
							{
								structuredData: "PROFESSORS",
							},
						],
					},
				},
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProfessorDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProfessorDetail/thumbnail@2x.png",
	},
};

export default schema;
