import { OdsCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<OdsCardProps> = {
	schemaType: "component",
	component: "OdsCard",
	displayName: "OdsCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: true, mandatory: false },
				{
					...richtext,
					title: "Content",
					key: "content",
				},
				{
					type: "TextField",
					key: "prefix",
					title: "Prefix",
					hideable: true,
				},
				{
					type: "NumberField",
					hideable: true,
					key: "number",
					title: "Number",
				},
				{
					type: "TextField",
					key: "suffix",
					title: "Suffix",
					hideable: true,
				},
				{
					title: "Align Icon",
					type: "RadioGroup",
					key: "alignIcon",
					options: [
						{ name: "left", value: "left", title: "Left" },
						{ name: "right", value: "right", title: "Right" },
						{ name: "top", value: "top", title: "Top" },
						{ name: "bottom", value: "bottom", title: "Bottom" },
					],
				},
				{
					title: "Icon",
					type: "RadioGroup",
					key: "iconName",
					hideable: true,
					options: [
						{ name: "Icon1", value: "Icon1", title: "Icon 1" },
						{ name: "Icon2", value: "Icon2", title: "Icon 2" },
						{ name: "Icon3", value: "Icon3", title: "Icon 3" },
						{ name: "Icon4", value: "Icon4", title: "Icon 4" },
						{ name: "Icon5", value: "Icon5", title: "Icon 5" },
					],
				},
				{
					title: "Background color",
					type: "RadioGroup",
					key: "colorSelection",
					options: [
						{ name: "primary", value: "primary", title: "Primary" },
						{ name: "secondary", value: "secondary", title: "Secondary" },
						{ name: "default", value: "default", title: "Default" },
					],
				},
			],
		},
	],

	default: {
		component: "OdsCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		content: "Subtitle",
		number: 0,
		prefix: null,
		suffix: "%",
		alignIcon: "left",
		iconName: "Icon1",
		colorBackground: "primary",
	},

	thumbnails: {
		"1x": "/thumbnails/components/OdsCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/OdsCard/thumbnail@2x.png",
	},
};

export default schema;
