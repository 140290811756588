import { Schema } from "@griddo/core";

export const ODS: Schema.SimpleContentType = {
	dataPacks: ["ODS_PACK"],
	title: "ODS",
	local: true,
	translate: true,
	fromPage: false,
	schema: {
		fields: [
			{
				title: "Number",
				type: "TextField",
				humanReadable: true,
				key: "number",
			},
			{
				title: "Name",
				type: "TextField",
				humanReadable: true,
				key: "title",
			},
			{
				title: "Color",
				type: "ColorPicker",
				colors: [
					"#CBCCCD",
					"#e5243b",
					"#dda83a",
					"#4c9f38",
					"#c5192d",
					"#ff3a21",
					"#26bde2",
					"#fcc30b",
					"#a21942",
					"#fd6925",
					"#dd1367",
					"#fd9d24",
					"#bf8b2e",
					"#3f7e44",
					"#0a97d9",
					"#56c02b",
					"#00689d",
					"#19486a",
				],
				key: "color",
			},
			{
				title: "Icon",
				type: "TextField",
				key: "icon",
			},
		],
	},
	clone: null,
	defaultValues: null,
	//	editable: false,
};
