import { FeaturedContentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	heading,
	image,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";
import { wysiwyg } from "@schemas/presets/wysiwyg";

const schema: Schema.Module<FeaturedContentProps> = {
	schemaType: "module",
	component: "FeaturedContent",
	category: "content",
	displayName: "Featured Content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...image,
					mandatory: true,
				},
				{ ...heading },
				{
					...wysiwyg,
					title: "Content",
					key: "content",
				},
				{
					...wysiwyg,
					title: "Claim",
					key: "claim",
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],
	default: {
		component: "FeaturedContent",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		claim: "Lorem ipsum paragraph",
		link: {
			...defaultLink,
			text: "Saber más",
			variant: "tertiary",
		},
		image: {
			component: "Image",
		},
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedContent/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedContent/thumbnail@2x.png",
	},
};

export default schema;
