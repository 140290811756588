import { Schema } from "@griddo/core";

export const PROFESSOR_TYPE: Schema.CategoryContentType = {
	dataPacks: ["PROFESSORS_PACK"],
	title: "Professor type (Auto)",
	local: true,
	translate: true,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
