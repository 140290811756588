import { UIFields, Fields } from "@griddo/core";

export const link: UIFields.ComponentContainer = {
	type: "ComponentContainer",
	title: "Button",
	key: "link",
	whiteList: ["Button"],
};

type DefaultLinkValues = {
	component: "Button";
	url: Fields.Url;
};

export const defaultLink: DefaultLinkValues = {
	component: "Button",
	url: {
		href: undefined,
		linkToURL: undefined,
		newTab: false,
		noFollow: false,
	},
};
