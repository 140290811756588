import { ChartCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import Chart from "@schemas/components/Chart";
import { anchorID, detail, heading, verticalSpacing } from "@schemas/presets";

const schema: Schema.Module<ChartCollectionProps> = {
	schemaType: "module",
	displayName: "Chart Collection",
	component: "ChartCollection",
	category: "collection",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					hideable: true,
					mandatory: false,
				},
				{
					...detail,
					hideable: true,
				},
				{
					title: "Elements",
					key: "elements",
					type: "ComponentArray",
					contentType: "components",
					whiteList: ["Chart"],
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "ChartCollection",
		title: { content: "Title", tag: "h2" },
		anchorID: null,
		verticalSpacing: "medium",
		elements: [
			{ ...Chart.default },
			{ ...Chart.default },
			{ ...Chart.default },
		],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ChartCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ChartCollection/thumbnail@2x.png",
	},
};

export default schema;
