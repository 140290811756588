import { Core, useSite } from "@griddo/core";

// Custom renderer, taking them from Griddo and add storybook renderer.
type CustomRenderers = Core.Renderers | "storybook";

export function useRenderer() {
	const { renderer } = useSite();
	const customRenderer = renderer as CustomRenderers;

	return {
		isSSG: customRenderer === "gatsby",
		isEditor: customRenderer === "editor",
		isPreview: customRenderer === "preview",
		isStorybook: customRenderer === "storybook",
	};
}
