import { ModalLinkCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<ModalLinkCardProps> = {
	schemaType: "component",
	component: "ModalLinkCard",
	displayName: "ModalLinkCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...richtext, title: "Title", key: "title" },
				{ ...richtext, title: "Link", key: "link" },
				{
					type: "FieldGroup",
					key: "modalFields",
					title: "Modal fields",
					fields: [
						{ type: "TextField", title: "Modal title", key: "modalTitle" },
						{
							type: "TextField",
							title: "Modal subtitle",
							key: "modalSubtitle",
						},
						{
							...richtext,
							title: "Modal content",
							key: "modalContent",
						},
					],
				},
			],
		},
	],

	default: {
		component: "ModalLinkCard",
		title: "Title",
		link: "Subtitle",
		modalTitle: "Lorem ipsum paragraph",
		modalSubtitle: "Lorem ipsum paragraph",
		modalContent: "Lorem ipsum paragraph",
	},

	thumbnails: {
		"1x": "/thumbnails/components/ModalLinkCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/ModalLinkCard/thumbnail@2x.png",
	},
};

export default schema;
