//
// Griddo Components
//
// Every Component and their types must be imported and reexported.
// Yes, a barrel index :)

import { DepartmentsBarProps, OdsCardProps } from "@autoTypes";
import { Component } from "@griddo/core";
import * as React from "react"; // Types
import type { QuoteTestimonialProps } from "src/ui/modules/QuoteTestimonial";

import type { AccordionComponentProps } from "./AccordionComponent";
import type { BackgroundImageProps } from "./BackgroundImage";
import type { BasicFormProps } from "./BasicForm";
import type { BasicCardProps } from "./Cards/BasicCard";
import type { CypherCardProps } from "./Cards/CypherCard";
import type { ImageCardProps } from "./Cards/ImageCard";
import type { ModalLinkCardProps } from "./Cards/ModalLinkCard";
import type { OrderedCardProps } from "./Cards/OrderedCard";
import type { ChartProps } from "./Chart";
import type { FeatureComponentProps } from "./FeatureComponent";
import type { HeroSlideProps } from "./HeroSlide";
import type { LocationComponentProps } from "./LocationComponent";
import type { ProgramListTabComponentProps } from "./ProgramListTabComponent";
import type { QuickFactsProps } from "./QuickFacts";
import type { TabsProps } from "./TabsComponent";
import type { VideoComponentProps } from "./VideoComponent";

// Components
const AccordionComponent = React.lazy(() => import("./AccordionComponent"));
const BackgroundImage = React.lazy(() => import("./BackgroundImage"));
const BasicCard = React.lazy(() => import("./Cards/BasicCard"));
const BasicForm = React.lazy(() => import("./BasicForm"));
const Chart = React.lazy(() => import("./Chart"));
const CypherCard = React.lazy(() => import("./Cards/CypherCard"));
const FeatureComponent = React.lazy(() => import("./FeatureComponent"));
const HeroSlide = React.lazy(() => import("./HeroSlide"));
const ImageCard = React.lazy(() => import("./Cards/ImageCard"));
const LocationComponent = React.lazy(() => import("./LocationComponent"));
const ModalLinkCard = React.lazy(() => import("./Cards/ModalLinkCard"));
const DepartmentsBar = React.lazy(() => import("./DepartmentsBar"));
const OdsCard = React.lazy(() => import("./Cards/OdsCard"));
const OdsCardRows = React.lazy(() => import("./OdsCardRows"));
const OrderedCard = React.lazy(() => import("./Cards/OrderedCard"));
const PeopleCard = React.lazy(() => import("./Cards/PeopleCard"));
const ProgramListTabComponent = React.lazy(
	() => import("./ProgramListTabComponent")
);
const ProgramNavigationTab = React.lazy(() => import("./ProgramNavigationTab"));
const QuickFacts = React.lazy(() => import("./QuickFacts"));
const TabsComponent = React.lazy(() => import("./TabsComponent"));
const VideoComponent = React.lazy(() => import("./VideoComponent"));

// Estas Cards no son un componente normal de Griddo, es más un `element` pero
// está en component/Cards por coherencia. Aquí tan solo exportaremos como
// componente, ni types ni añadirla a la constante `components` para el
// `GriddoComponent`.
const NewsCard = React.lazy(() => import("./Cards/NewsCard"));
const ProgramCard = React.lazy(() => import("./Cards/ProgramCard"));

// Components for <GriddoComponent>
const components = {
	AccordionComponent,
	BackgroundImage,
	BasicCard,
	BasicForm,
	Chart,
	CypherCard,
	DepartmentsBar,
	FeatureComponent,
	HeroSlide,
	ImageCard,
	LocationComponent,
	ModalLinkCard,
	OdsCard,
	OdsCardRows,
	OrderedCard,
	ProgramListTabComponent,
	ProgramNavigationTab,
	QuickFacts,
	TabsComponent,
	VideoComponent,
};

// Types for <GriddoComponent>
type ComponentProps =
	| AccordionComponentProps
	| BackgroundImageProps
	| BasicCardProps
	| BasicFormProps
	| ChartProps
	| CypherCardProps
	| DepartmentsBarProps
	| FeatureComponentProps
	| HeroSlideProps
	| ImageCardProps
	| LocationComponentProps
	| ModalLinkCardProps
	| OdsCardProps
	| QuoteTestimonialProps
	| OrderedCardProps
	| ProgramListTabComponentProps
	| QuickFactsProps
	| TabsProps
	| VideoComponentProps;

// Component named export
export {
	AccordionComponent,
	BackgroundImage,
	BasicCard,
	BasicForm,
	Chart,
	CypherCard,
	DepartmentsBar,
	FeatureComponent,
	HeroSlide,
	ImageCard,
	LocationComponent,
	ModalLinkCard,
	NewsCard,
	OdsCard,
	OdsCardRows,
	OrderedCard,
	PeopleCard,
	ProgramCard,
	ProgramListTabComponent,
	ProgramNavigationTab,
	QuickFacts,
	TabsComponent,
	VideoComponent,
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------- You shall not pass! ----------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------------------------------------------- Gandalf ----
// -----------------------------------------------------------------------------

// Griddo bundle export
export default components;

/**
 * `<GriddoComponent>`
 *
 * Render components with their Types dinamically and wrapped them with Griddo
 * functionalities.
 *
 * @example
 * <GriddoComponent component="BasicCard" {...basicCardProps} />
 *
 * Check out the [documentation](https://griddoio.notion.site/GriddoComponent-y-GriddoModule-en-la-instancia-bad16303bb4b49e0b696b62d1f6c40e9)
 */
export const GriddoComponent = (props: ComponentProps) => (
	<Component libComponents={components} {...props} />
);
