import { HeroLandingProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, heading, image } from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<HeroLandingProps> = {
	schemaType: "module",
	component: "HeroLanding",
	displayName: "HeroLanding",
	category: "hero",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },
				{
					...richtext,
					title: "Subtitle",
					key: "subtitle",
				},
				{ ...image },
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],

	default: {
		component: "HeroLanding",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: "Subtitle",
		intro: "Intro",
		image: {
			component: "Image",
			veilOpacity: 0.2,
			decoding: "sync",
			fetchpriority: "high",
			loading: "eager",
			quality: 75,
		},
		link: { component: "Button" },
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeroLanding/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroLanding/thumbnail@2x.png",
	},
};

export default schema;
