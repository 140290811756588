import { CypherCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<CypherCardProps> = {
	schemaType: "component",
	component: "CypherCard",
	displayName: "Cypher Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "prefix",
					title: "Prefix",
					hideable: true,
				},
				{
					type: "NumberField",
					key: "number",
					title: "Number",
				},
				{
					type: "ConditionalField",
					title: "Hover effect",
					key: "hoverEffect",
					options: [
						{ value: true, title: "Activate", name: "hoverEffect" },
						{ value: false, title: "No activate", name: "hoverEffect" },
					],
					fields: [
						{
							...richtext,
							condition: false,
							title: "Content",
							key: "content",
						},
						{
							...richtext,
							condition: true,
							key: "hoverCardText",
							title: "Hover card text",
							hideable: true,
						},
					],
				},
				{
					type: "TextField",
					key: "suffix",
					title: "Suffix",
					hideable: true,
				},
				{ ...heading, hideable: false },
				{
					type: "TextField",
					key: "hoverCardTitle",
					title: "Hover card title",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "CypherCard",
		hoverEffect: false,
		hoverCardTitle: "Lorem ipsum",
		hoverCardText:
			"Morbi pharetra, nulla in condimentum consequat, nisi ipsum rhoncus lectus, a pulvinar mauris leo ut magna. In tristique ut leo eu mollis.",
		activeAnimation: true,
		number: 0,
		prefix: null,
		suffix: null,
		title: { content: "Lorem ipsum", tag: "h2" },
		content: "Subtitle",
	},

	thumbnails: {
		"1x": "/thumbnails/components/CypherCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/CypherCard/thumbnail@2x.png",
	},
};

export default schema;
