import { FullVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID } from "@schemas/presets";

const schema: Schema.Module<FullVideoProps> = {
	schemaType: "module",
	component: "FullVideo",
	displayName: "Full Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Video",
					key: "video",
					whiteList: ["VideoComponent"],
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],

	default: {
		component: "FullVideo",
		video: {
			component: "VideoComponent",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FullVideo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FullVideo/thumbnail@2x.png",
	},
};

export default schema;
