import { OdsCardModalProps, ODSContentTypeProps } from "@autoTypes";
import { Fields, Schema } from "@griddo/core";
import OdsCardRows from "@schemas/components/OdsCardRows";

export interface OdsCardModalPropsExtension extends OdsCardModalProps {
	data?: Fields.Reference<ODSContentTypeProps>;
	queriedItems?: Fields.QueriedData<ODSContentTypeProps>;
}
export enum Component {
	name = "OdsCardModal",
}

export const OdsCardsModalMap: Array<OdsCardModalPropsExtension> = [
	{
		component: Component.name,
		title: "Fin de la pobreza",
		position: "01",
		data: {
			mode: "manual",
			fixed: [8],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Hambre cero",
		position: "02",
		data: {
			mode: "manual",
			fixed: [9],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Salud y bienestar",
		position: "03",
		data: {
			mode: "manual",
			fixed: [10],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Educación de calidad",
		position: "04",
		data: {
			mode: "manual",
			fixed: [11],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Igualdad de género",
		position: "05",
		data: {
			mode: "manual",
			fixed: [12],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Agua limpia y saneamiento",
		position: "06",
		data: {
			mode: "manual",
			fixed: [13],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Energía asequible y no contaminante",
		position: "07",
		data: {
			mode: "manual",
			fixed: [14],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Trabajo decente y crecimiento económico",
		position: "08",
		data: {
			mode: "manual",
			fixed: [15],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Industria, innovacion e infraestructura",
		position: "09",
		data: {
			mode: "manual",
			fixed: [16],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Reducción de las desigualdades",
		position: "10",
		data: {
			mode: "manual",
			fixed: [17],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Ciudades y comunidades sostenibles",
		position: "11",
		data: {
			mode: "manual",
			fixed: [18],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Producción y consumo responsables",
		position: "12",
		data: {
			mode: "manual",
			fixed: [19],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Acción por el clima",
		position: "13",
		data: {
			mode: "manual",
			fixed: [20],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Vida submarina",
		position: "14",
		data: {
			mode: "manual",
			fixed: [21],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Vida de ecosistemas terrestres",
		position: "15",
		data: {
			mode: "manual",
			fixed: [22],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Paz, justicia e instituciones sólidas",
		position: "16",
		data: {
			mode: "manual",
			fixed: [23],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
	{
		component: Component.name,
		title: "Alianzas para lograr los objetivos",
		position: "17",
		data: {
			mode: "manual",
			fixed: [24],
			fullRelations: false,
		},
		elements: [
			{
				...OdsCardRows.default,
			},
		],
	},
];
const schema: Schema.Component<OdsCardModalProps> = {
	schemaType: "component",
	displayName: "OdsCardModal",
	component: "OdsCardModal",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "title",
					title: "Title",
					mandatory: true,
					humanReadable: true,
					readonly: true,
				},
				{
					type: "TextField",
					key: "position",
					title: "Position",
					mandatory: true,
					humanReadable: true,
					readonly: true,
				},
				{
					type: "ComponentArray",
					title: "ODS Cards row",
					key: "elements",
					whiteList: ["OdsCardRows"],
					contentType: "components",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "OdsCardModal",
		title: "ODS Cards row",
		position: "position",
		elements: [OdsCardRows.default],
	},
};

export default schema;
