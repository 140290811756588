import { OrderedCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	detail,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

import OrderedCard from "../components/Cards/OrderedCard";

const schema: Schema.Module<OrderedCollectionProps> = {
	schemaType: "module",
	component: "OrderedCollection",
	category: "collection",
	displayName: "Ordered Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					hideable: true,
					mandatory: false,
				},

				{
					...detail,
					hideable: true,
					mandatory: false,
				},
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["OrderedCard"],
					contentType: "components",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "OrderedCollection",
		title: null,
		detail: null,
		elements: [
			{ ...OrderedCard.default },
			{ ...OrderedCard.default },
			{ ...OrderedCard.default },
		],
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/OrderedCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/OrderedCollection/thumbnail@2x.png",
	},
};

export default schema;
