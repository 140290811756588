import { UIFields } from "@griddo/core";

export const verticalSpacing: UIFields.RadioGroup = {
	type: "RadioGroup",
	title: "Vertical Spacing",
	key: "verticalSpacing",
	mandatory: true,
	options: [
		{ name: "small", title: "Small", value: "small" },
		{ name: "medium", title: "Medium", value: "medium" },
		{ name: "large", title: "Large", value: "large" },
	],
};
