import { generateAutomaticDimensions } from "@analytics/generateAutomaticDimensions";
import components from "@ui/components";
import modules from "@ui/modules";
import templates from "@ui/templates";

import browser from "../builder.browser";
import ssr from "../builder.ssr";
import { SiteProvider } from "../griddo.root";

// Prepare components
const bundle = { ...components, ...modules };
// Dimensions

// Final exports
export {
	SiteProvider,
	browser,
	bundle as components,
	generateAutomaticDimensions,
	ssr,
	templates,
};
