import { UIFields } from "@griddo/core";

export const fileContainer: UIFields.ComponentArray = {
	title: "File container",
	type: "ComponentArray",
	key: "files",
	contentType: "components",
	maxItems: null,
	whiteList: ["FileItem"],
};
