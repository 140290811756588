import { VideoComponentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { image } from "@schemas/presets";

const schema: Schema.Component<VideoComponentProps> = {
	schemaType: "component",
	component: "Video",
	displayName: "Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "url",
					title: "URL video",
					mandatory: true,
				},
				{
					...image,
					title: "Thumbnail",
					key: "backgroundImage",
					hideable: true,
				},
				{
					title: "Alternative text",
					type: "TextField",
					key: "altText",
					placeholder: "Type an alternative text",
				},
			],
		},
	],

	default: {
		component: "VideoComponent",
		backgroundType: "image",
		backgroundImage: { component: "Image" },
		altText: "",
	},

	thumbnails: {
		"1x": "/thumbnails/components/Video/thumbnail@1x.png",
		"2x": "/thumbnails/components/Video/thumbnail@2x.png",
	},
};

export default schema;
