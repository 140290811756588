import { OdsCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { OdsCardsModalMap } from "@schemas/components/Cards/OdsCardModal";
import { heading } from "@schemas/presets";

const schema: Schema.Module<OdsCollectionProps> = {
	schemaType: "module",
	component: "OdsCollection",
	category: "collection",
	displayName: "Ods Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, key: "message", title: "Message" },
				{
					type: "TextField",
					title: "Detail",
					key: "detail",
				},
				{
					type: "ComponentArray",
					key: "elements",
					title: "Ods Cards",
					contentType: "components",
					whiteList: ["OdsCardModal"],
				},
				{
					type: "CheckGroup",
					key: "activeOds",
					title: "Active ODS",
					options: [
						{ name: "01", title: "01 Fin de la pobreza" },
						{ name: "02", title: "02 Hambre cero" },
						{ name: "03", title: "03 Salud y bienestar" },
						{ name: "04", title: "04 Educación de caludad" },
						{ name: "05", title: "05 Igualdad de género" },
						{ name: "06", title: "06 Agua limpia y saneamiento" },
						{ name: "07", title: "07 Energía asequible y no contaminante" },
						{ name: "08", title: "08 Trabajo decente y crecimiento económico" },
						{ name: "09", title: "09 Industria, innovación e infraestructura" },
						{ name: "10", title: "10 Reducción de las desigualdades" },
						{ name: "11", title: "11 Ciudades y comunidades sostenibles" },
						{ name: "12", title: "12 Producción y consumo responsables" },
						{ name: "13", title: "13 Acción por el clima" },
						{ name: "14", title: "14 Vida sumbarina" },
						{ name: "15", title: "15 Vida de ecosistemas terrestres" },
						{ name: "16", title: "16 Paz, justicia e instituciones sólidas" },
						{ name: "17", title: "17 Alianzas para lograr los objetivos" },
					],
				},
			],
		},
	],

	default: {
		component: "OdsCollection",
		title: "Titulo random",
		detail:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec ipsum sodales, suscipit dolor non, imperdiet dui. Pellentesque rhoncus mi nec ullamcorper faucibus. Vestibulum non leo nisi. Duis nec consectetur est. Fusce laoreet accumsan metus malesuada aliquet. Cras lacus tellus, sagittis eget malesuada sed, lobortis eu diam. Integer mollis dignissim nisl, eget porta sapien feugiat rhoncus. Donec tincidunt, est vel euismod blandit, ligula nisi gravida nisi, et dictum erat nibh vel mauris. Phasellus eu malesuada lectus. Proin rhoncus lorem orci, quis facilisis mi tincidunt sollicitudin.",
		elements: [...OdsCardsModalMap],
		message: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/OdsCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/OdsCollection/thumbnail@2x.png",
	},
};

export default schema;
