import { InnerWysiwygProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	detail,
	heading,
	link,
	themeSelector,
	verticalSpacing,
	wysiwyg,
} from "@schemas/presets";

const schema: Schema.Module<InnerWysiwygProps> = {
	schemaType: "module",
	component: "InnerWysiwyg",
	category: "content",
	displayName: "Inner Wysiwyg",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },
				{ ...detail },
				{ ...wysiwyg, key: "content", title: "Content" },
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "InnerWysiwyg",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		detail: "Detail",
		link: {
			component: "Button",
		},
		anchorID: null,
		content: "Lorem ipsum",
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Wysiwyg/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Wysiwyg/thumbnail@2x.png",
	},
};

export default schema;
