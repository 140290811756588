import throttle from "lodash.throttle";
import * as React from "react";

type UseInfinitePaginationProps = {
	/**
	 * Whether hook is active or not.
	 */
	active: boolean;
	/**
	 * A reference to an HTMLElement that is the list wrapper.
	 */
	wrapperRef: React.RefObject<HTMLElement> | null;
	/**
	 * A function called every time the page number is updated (when wrapper's bottom has been reached).
	 * @param page Page number
	 * @returns
	 */
	setPage: (page: number) => void;
};

export const useInfinitePagination = (
	props: UseInfinitePaginationProps
): [(value: boolean) => void, () => void] => {
	const { active, wrapperRef, setPage } = props;

	const infinitePage = React.useRef(1);
	const enabled = React.useRef(true);

	const setEnabled = (value: boolean) => {
		enabled.current = value;
	};

	const resetPagination = () => {
		infinitePage.current = 1;
	};

	React.useEffect(() => {
		if (typeof window !== "undefined" && wrapperRef?.current) {
			const onScroll = () => {
				if (wrapperRef?.current) {
					const clientHeight = window.screen.availHeight;
					const { bottom } = wrapperRef.current.getBoundingClientRect();

					if (enabled.current && bottom < clientHeight) {
						infinitePage.current = infinitePage.current + 1;
						setPage(infinitePage.current);
					}
				}
			};
			const onScrollThrottle = throttle(onScroll, 300);

			if (active) {
				window.addEventListener("scroll", onScrollThrottle);
				return () => {
					window.removeEventListener("scroll", onScrollThrottle);
				};
			}
		}
	}, []);

	return [
		/**
		 * A function that allows to enable / disable the update of page number.
		 */
		setEnabled,
		/**
		 * A function that can be called to reset page number to initial value.
		 */
		resetPagination,
	];
};
