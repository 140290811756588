import { FullImageWithTextProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, detail, heading, image } from "@schemas/presets";

const schema: Schema.Module<FullImageWithTextProps> = {
	schemaType: "module",
	component: "FullImageWithText",
	category: "content",
	displayName: "FullImageWithText",

	configTabs: [
		{
			title: "content",
			fields: [{ ...image }, { ...heading }, { ...detail }],
		},
		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],

	default: {
		component: "FullImageWithText",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		image: { component: "Image" },
		anchorID: null,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FullImageWithText/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FullImageWithText/thumbnail@2x.png",
	},
};

export default schema;
