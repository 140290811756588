import { HighlightQuoteProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	themeSelector,
	verticalSpacing,
	wysiwyg,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<HighlightQuoteProps> = {
	schemaType: "module",
	component: "HighlightQuote",
	category: "content",
	displayName: "HighlightQuote",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...richtext,
					title: "Quote 1",
					key: "quote1",
					hideable: false,
				},
				{
					...richtext,
					title: "Quote 2",
					key: "quote2",
					hideable: false,
					mandatory: true,
				},
				{ ...heading, mandatory: false },
				{ ...richtext, title: "Subtitle", key: "subtitle" },
				{ ...wysiwyg, title: "Content", key: "content", mandatory: true },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/HighlightQuote/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/HighlightQuote/layout2.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
				{
					type: "UniqueCheck",
					title: "Show quote icon",
					key: "showQuoteIcon",
					options: [{ title: "true" }],
				},
			],
		},
	],

	default: {
		component: "HighlightQuote",
		quote1: "Lorem ipsum",
		quote2: "Lorem ipsum",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
		subtitle: "Lorem ipsum paragraph",
		content: "Lorem ipsum <strong>paragraph</strong>",
		showQuoteIcon: true,
		layout: "layout1",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HighlightQuote/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HighlightQuote/thumbnail@2x.png",
	},
};

export default schema;
