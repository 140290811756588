import { Core } from "@griddo/core";

/**
 * Format and translate a string date from a `dateField` (YY/DD/MM) into differentes Locales.
 * You can pass an option object (Intl.DateTimeFormatOptions) to customize the output.
 * @example
 * formatLocaleDate("2023/07/09", "en-ES")
 * // 7 Sep 2023
 */
export function formatLocaleDate(
	dateString?: string,
	locale?: Core.ISOLocale,
	options: Intl.DateTimeFormatOptions = {
		dateStyle: "long",
	}
) {
	if (!dateString) {
		return "";
	}

	if (!locale) {
		return dateString;
	}

	const date = new Date(dateString);

	return date.toLocaleDateString(locale, options);
}
