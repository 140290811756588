import * as React from "react";
import { ChangeEvent, useEffect } from "react";

type EntryState = Record<string, Array<string>>;

export type FieldValidation = {
	validate: RegExp | null;
	errors: boolean;
};

export type FieldSValidation = {
	[fieldName: string]: FieldValidation;
};

type AddNewFieldProps = {
	element: HTMLInputElement;
	dataAttributeValue?: string;
};

export type HookFormValuesProps = {
	formValues: EntryState | undefined;
	errors: FieldSValidation;
	updateFormValues: (event: ChangeEvent<HTMLFormElement>) => void;
	validateForm: () => boolean;
	registerNewField: ({ element, dataAttributeValue }: AddNewFieldProps) => void;
};

type FormField = HTMLElement | null;

/**
 * Returns an object with the structure { key: [] }. Being the key the name of the data-useForm and the array the values from the forms.
 */
export const useFormValues = (props: {
	/** A reference to a form to get input elements values with the data attribute. */
	formRef: React.MutableRefObject<HTMLFormElement | null>;
	/** The name of the `data-*` attribute to filter the selects. Default `useForm`. */
	dataAttributeName?: string;
	/** If true the hook remove keys with empty arrays. Default `true` */
	isSanitized?: boolean;
}): HookFormValuesProps => {
	const { formRef, dataAttributeName = "useForm", isSanitized = true } = props;

	// Selection ids state.
	const [formValues, setFormValues] = React.useState<EntryState>();
	// All fields into form.
	const [errors, setErrors] = React.useState<FieldSValidation>({});
	// // Status to check the form for errors

	const AddNewField = ({ element, dataAttributeValue }: AddNewFieldProps) => {
		if (
			dataAttributeValue &&
			!Object.prototype.hasOwnProperty.call(errors, dataAttributeValue)
		) {
			const validationAttribute = element.dataset.validate;
			const isRequired = element.dataset.required ? new RegExp(/.+/) : null;

			const field: FieldValidation = {
				validate: isRequired,
				errors: false,
			};

			if (validationAttribute) {
				try {
					field.validate = new RegExp(validationAttribute);
				} catch (error) {
					// eslint-disable-next-line no-console
					console.error(
						`Invalid regular expression for field: ${validationAttribute}`
					);
				}
			}

			return { [dataAttributeValue.toLowerCase()]: field };
		}

		return null;
	};

	// Fill errors when the component is first mounted
	useEffect(() => {
		if (formRef.current) {
			const elements = formRef.current.elements;

			let _errors = {};

			for (let i = 0; i < elements.length; i++) {
				const element = elements[i] as HTMLInputElement;
				const dataAttributeValue =
					element.dataset[dataAttributeName.toLowerCase()];

				if (dataAttributeValue) {
					_errors = {
						..._errors,
						...AddNewField({ element, dataAttributeValue }),
					};
				}
			}
			setErrors(_errors);
		}
	}, []);

	const getFormFields = (): Array<FormField> => {
		if (formRef.current === null) {
			return [];
		}
		const formElements = formRef.current.elements;

		return Array.from(formElements).filter((formElement) => {
			const element = formElement as HTMLElement;
			const dataUseForm = element.getAttribute(`data-${dataAttributeName}`);
			if (element.tagName.toLowerCase() === "input" && dataUseForm) {
				return formElement;
			}

			return null;
		}) as Array<FormField>;
	};

	const setFieldValueAndError = (
		element: HTMLInputElement | HTMLFormElement
	) => {
		const _errors = { ...errors };
		let _formValues = {} as EntryState;
		if (element) {
			const name = element.getAttribute("name");
			const value = element.value;
			const type = (element as HTMLInputElement).type;

			if (
				name &&
				Object.prototype.hasOwnProperty.call(_errors, name.toLowerCase())
			) {
				_formValues = { [name]: [] };

				_errors[name.toLowerCase()].errors = !validateField(
					name.toLowerCase(),
					value,
					element
				);

				if (type === "checkbox") {
					getFormFields().forEach((element) => {
						const _element = element as HTMLInputElement;
						if (_element?.name === name) {
							const isChecked = (element as HTMLInputElement).checked;
							if (isChecked) {
								_formValues[name].push(value === "" ? "1" : value);
							} else {
								_formValues[name].push("0");
							}
						}
					});
				} else {
					_formValues[name].push(value.toString());
				}
			}
		}

		setErrors(_errors);
		return _formValues;
	};

	function fillValues(_formValues: EntryState) {
		// Remove keys with empty arrays
		const formEntriesSanitized: EntryState = {};
		Object.keys(_formValues).forEach((key) => {
			if (key.length > 0) {
				formEntriesSanitized[key] = _formValues[key];
			}
		});

		// Set the state with forms input values
		setFormValues(isSanitized ? formEntriesSanitized : _formValues);
	}

	const validateForm = (): boolean => {
		const formElements = getFormFields();
		let _hasErrors = false;
		let _formValues = {} as EntryState;

		formElements.forEach((formElement) => {
			const element = formElement as HTMLElement;
			const dataUseForm = element.getAttribute(`data-${dataAttributeName}`);

			if (element.tagName.toLowerCase() === "input" && dataUseForm) {
				_formValues = {
					..._formValues,
					...setFieldValueAndError(element as HTMLInputElement),
				};
			}
		});

		fillValues(_formValues);

		Object.keys(errors).forEach((error) => {
			if (errors[error].errors) {
				_hasErrors = true;
			}
		});

		return _hasErrors;
	};

	function validateField(
		fieldName: string,
		value: string,
		element: HTMLInputElement | HTMLFormElement
	): boolean {
		const type = element?.type;
		const field = errors[fieldName];

		if (!field || !field.validate) {
			return true;
		}

		if (type === "checkbox") {
			return element?.checked;
		}

		field.validate.lastIndex = 0;
		return field.validate.test(value);
	}

	function updateFormValues(event: ChangeEvent<HTMLFormElement>): void {
		fillValues({ ...formValues, ...setFieldValueAndError(event?.target) });
	}

	function registerNewField({ element, dataAttributeValue }: AddNewFieldProps) {
		let _errors = { ...errors };

		if (
			dataAttributeValue &&
			!Object.prototype.hasOwnProperty.call(errors, dataAttributeValue)
		) {
			_errors = { ..._errors, ...AddNewField({ element, dataAttributeValue }) };
		}

		setErrors(_errors);
	}

	return {
		/** An object with the structure { key: [] } with the values from the form */
		formValues,
		/** An array containing the names of all registered fields */
		errors,
		/** A function to update the formValues state */
		updateFormValues,
		/** A function validate form */
		validateForm,
		/** A function to register new fields */
		registerNewField,
	};
};
